import { Percent } from '@uniswap/sdk-core'
import { OpacityHoverState } from 'components/Common'
import { useDispatch } from 'react-redux'
import { updateInputs } from 'state/limit/actions'
import { Field } from 'state/swap/actions'
import {
  // useDefaultsFromURLSearch,
  // useDerivedSwapInfo,
  useSwapActionHandlers,
} from 'state/swap/hooks'
import styled from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'

const StyledSwapHeader = styled.div`
  padding: 8px 12px;
  margin-bottom: 8px;
  width: 100%;
  color: ${({ theme }) => theme.textSecondary};
`

const Filter = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  border-radius: 16px;
  padding: 4px;
`

const Selector = styled.div<{ active: boolean }>`
  padding: 8px 12px;
  border-radius: 12px;
  background: ${({ active, theme }) => (active ? theme.accentActiveSoft : 'none')};
  cursor: pointer;

  ${OpacityHoverState}
`

const StyledSelectorText = styled(ThemedText.SubHeader)<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.textPrimary : theme.textSecondary)};
`

export default function SwapHeader({
  isMarket,
  setIsMarket,
  // onSwitchOrderType,
  allowedSlippage,
  inputValueLimit,
  inputValueMarket,
}: {
  isMarket: boolean
  setIsMarket: React.Dispatch<React.SetStateAction<boolean>>
  // onSwitchOrderType: () => void
  allowedSlippage: Percent
  inputValueLimit: string
  inputValueMarket: string
}) {
  const { onUserInput } = useSwapActionHandlers()
  const dispatch = useDispatch()
  return (
    <StyledSwapHeader>
      <RowBetween>
        <RowFixed>
          <Filter
            onClick={() => {
              // onSwitchOrderType()
              // If Limit => Market
              // console.log('switching toggle', inputValueLimit)
              if (!isMarket) {
                onUserInput(Field.INPUT, inputValueLimit)
              } else {
                // Market => Limit
                dispatch(updateInputs({ updatedField: 'limitInput', newValue: inputValueMarket }))
              }
              setIsMarket(!isMarket)
            }}
          >
            <Selector active={!isMarket}>
              <StyledSelectorText lineHeight="20px" active={!isMarket}>
                Limit
              </StyledSelectorText>
            </Selector>
            <Selector active={isMarket}>
              <StyledSelectorText lineHeight="20px" active={isMarket}>
                Market
              </StyledSelectorText>
            </Selector>
          </Filter>
        </RowFixed>
        {isMarket && (
          <RowFixed>
            <SettingsTab placeholderSlippage={allowedSlippage} />
          </RowFixed>
        )}
      </RowBetween>
    </StyledSwapHeader>
  )
}
