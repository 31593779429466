import { Trans } from '@lingui/macro'
import { InterfaceElementName } from '@uniswap/analytics-events'
import { ReactNode } from 'react'
import { Text } from 'rebass'

import { ButtonError } from '../Button'
import { AutoRow } from '../Row'
import { SwapCallbackError } from '../swap/styleds'

// eslint-disable-next-line import/no-unused-modules
export default function LimitModalFooter({
  onConfirm,
  limitErrorMessage,
}: {
  onConfirm: () => void
  limitErrorMessage: ReactNode | undefined
}) {
  return (
    <>
      <AutoRow>
        <ButtonError
          onClick={onConfirm}
          disabled={false}
          style={{ margin: '10px 0 0 0' }}
          id={InterfaceElementName.CONFIRM_SWAP_BUTTON}
        >
          <Text fontSize={20} fontWeight={500}>
            <Trans>Confirm Limit Order</Trans>
          </Text>
        </ButtonError>

        {limitErrorMessage ? <SwapCallbackError error={limitErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
