// yarn add @opensea/seaport-js
import { AddressZero } from '@ethersproject/constants'
import { Web3Provider } from '@ethersproject/providers'
import { Trade } from '@uniswap/router-sdk'
import { Currency, TradeType } from '@uniswap/sdk-core'
import { API, graphqlOperation } from 'aws-amplify'
import { createOrder2 } from 'graphql_aws/mutations'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { Seaport } from 'opensea/seaport-js'
import { v4 as uuidv4 } from 'uuid'
// import { OPENSEA_DEFAULT_CROSS_CHAIN_CONDUIT_KEY, OPENSEA_KEY_TO_CONDUIT } from 'nft/queries/openSea'

const MAX_EXPIRATION_MONTHS = 3
const ZONE = '0xACcAb428285AA6368fCbDFb1bBfE9f05FC723ffC'

// const DEFAULT_ZONE_BY_NETWORK = new Map<number | undefined, string>([
//   [1, '0x004c00500000ad104d7dbd00e3ae0a5c00560c00'],
//   [4, '0x00000000e88fe2628ebc5da81d2b3cead633e89e'],
//   [5, '0x0000000000000000000000000000000000000000'],
// ])

// const OPENSEA_DEFAULT_CROSS_CHAIN_CONDUIT_KEY = '0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000'
// const OPENSEA_CROSS_CHAIN_CONDUIT = '0x1e0049783f008a0085193e00003d00cd54003c71' // To be approved ERC20
// const OPENSEA_KEY_TO_CONDUIT = { [OPENSEA_DEFAULT_CROSS_CHAIN_CONDUIT_KEY]: OPENSEA_CROSS_CHAIN_CONDUIT }

// const createWalletIfNotExists = /* GraphQL */ `
//   mutation CreateWallet($input: CreateWalletInput!, $condition: ModelWalletConditionInput) {
//     createWallet(input: $input, condition: $condition) {
//       chainId
//       address
//       chainIdAndAddress
//       createdAt
//       updatedAt
//     }
//   }
// `

/**
 * The longest time that an order is valid for is six months from the current date
 * @returns unix timestamp
 */
const getMaxOrderExpirationTimestamp = () => {
  const maxExpirationDate = new Date()
  maxExpirationDate.setMonth(maxExpirationDate.getMonth() + MAX_EXPIRATION_MONTHS)
  maxExpirationDate.setDate(maxExpirationDate.getDate() - 1)
  return Math.round(maxExpirationDate.getTime() / 1000)
}

// eslint-disable-next-line import/no-unused-modules
export async function createSellOrder(
  account: string | undefined,
  chainId: number | undefined,
  provider: Web3Provider | undefined,
  trade: Trade<Currency, Currency, TradeType> | undefined,
  expiration: number | undefined,
  limitOrderInputValue: string,
  limitOrderOutputValue: string
) {
  if (!account) throw new Error('Missing account')
  if (!chainId) throw new Error('Missing chainId')
  if (!provider) throw new Error('Missing web3 provider')
  if (!trade) throw new Error('Missing limit order')
  if (!trade.inputAmount) throw new Error('Missing input amount')
  if (!trade.outputAmount) throw new Error('Missing output amount')
  if (!trade.inputAmount.currency) throw new Error('Missing input token')
  if (!trade.outputAmount.currency) throw new Error('Missing output token')
  if (trade.inputAmount.currency.isNative) throw new Error('Input must be an ERC20 token')

  // TODO: In approve conduit, the model should be changed. Right now it is saying Waiting for limit order, should be waiting for approvement.
  const itemType = 1 // ERC20
  const inTokenAddress = trade.inputAmount.currency.wrapped.address
  const tokenId = '0' // tokenId is '0' for ERC20
  const inAmount = tryParseCurrencyAmount(limitOrderInputValue, trade.inputAmount.currency)?.quotient.toString(10) // trade.inputAmount.quotient.toString(10) // `0x${trade.inputAmount.quotient.toString(16)}`
  if (inAmount == null || inAmount === '0') {
    throw new Error('Sell amount cannot be 0')
  }
  const inSymbol = trade.inputAmount.currency.symbol
  const inDecimals = trade.inputAmount.currency.decimals
  const inItems = [{ itemType, token: inTokenAddress, identifier: tokenId, amount: inAmount }]

  const isOutNative = trade.outputAmount.currency.isNative
  const outTokenAddress = isOutNative ? AddressZero : trade.outputAmount.currency.wrapped.address
  const outAmount = tryParseCurrencyAmount(limitOrderOutputValue, trade.outputAmount.currency)?.quotient.toString(10) // trade.outputAmount.quotient.toString(10)
  if (outAmount == null || outAmount === '0') {
    throw new Error('Buy amount cannot be 0')
  }
  const outSymbol = trade.outputAmount.currency.symbol
  const outDecimals = trade.outputAmount.currency.decimals

  // TODO: move these three fields to backend
  const isCancelled = false
  const totalFilled = '0'
  const totalSize = '1'

  // TODO: The outAmount is 0 might be dangerous, double check in the future.
  const outItems = [{ token: outTokenAddress, amount: outAmount ?? '0', endAmount: outAmount }]

  // const ethersProvider = new Web3Provider(provider?.provider as ExternalProvider)
  // const seaport = new Seaport(ethersProvider, {
  //   conduitKeyToConduit: OPENSEA_KEY_TO_CONDUIT,
  //   overrides: { defaultConduitKey: OPENSEA_DEFAULT_CROSS_CHAIN_CONDUIT_KEY },
  // })
  // const seaport = new Seaport(ethersProvider)

  // const ethersProvider = new Web3Provider(provider?.provider as ExternalProvider)
  // const seaport = new Seaport(provider.getSigner(), {
  //   conduitKeyToConduit: OPENSEA_KEY_TO_CONDUIT,
  //   overrides: { defaultConduitKey: OPENSEA_DEFAULT_CROSS_CHAIN_CONDUIT_KEY },
  //   seaportVersion: '1.4',
  // })
  const seaport = new Seaport(provider.getSigner(), {
    seaportVersion: '1.4',
  })

  const currentTimestampInSeconds: number = Math.floor(Date.now() / 1000)
  const startTime = currentTimestampInSeconds.toString() // Current time

  if (!expiration) {
    expiration = getMaxOrderExpirationTimestamp()
  }

  // const { executeAllActions } = await seaport.createOrder(
  //   {
  //     offer: inItems,
  //     consideration: outItems,
  //     startTime,
  //     endTime: expiration.toString(),
  //     zone: chainId ? DEFAULT_ZONE_BY_NETWORK.get(chainId) : undefined,
  //     domain: undefined,
  //     salt: undefined,
  //     restrictedByZone: true,
  //     allowPartialFills: true,
  //   },
  //   account
  // )
  // const { executeAllActions } = await seaport.createOrder(
  //   {
  //     offer: inItems,
  //     consideration: outItems,
  //     startTime,
  //     endTime: expiration.toString(),
  //     zone: undefined,
  //     domain: undefined,
  //     salt: undefined,
  //     restrictedByZone: false,
  //     allowPartialFills: true,
  //   },
  //   account
  // )

  const { executeAllActions } = await seaport.createOrder(
    {
      offer: inItems,
      consideration: outItems,
      startTime,
      endTime: expiration.toString(),
      zone: ZONE,
      restrictedByZone: true,
      allowPartialFills: true,
    },
    account
  )

  // All currency tokens in the order must be the same token
  // Commented out line 428 in node_modules/@opensea/seaport-js/lib/seaport.js
  // throw new Error("All currency tokens in the order must be the same token");

  const order = await executeAllActions()
  // console.log('const order = ', JSON.stringify(order))

  try {
    //
    const limitOrder = {
      chainId,
      orderId: uuidv4(), // TODO: make sure no collision
      account,
      chainIdAndAccount: `${chainId}#${account}`,
      expiration,
      inputToken: inTokenAddress,
      outputToken: outTokenAddress,
      inputAmount: inAmount,
      outputAmount: outAmount,
      // executionPrice: parseFloat(trade.executionPrice.toSignificant(6)),
      // TODO: raise error?
      executionPrice: parseFloat(outAmount ?? '0') / parseFloat(inAmount ?? '1'),
      startTimestamp: currentTimestampInSeconds,
      inSymbol,
      inDecimals,
      outSymbol,
      outDecimals,
      isCancelled,
      totalFilled,
      totalSize,
      chainIdAndTokenPairString: `${chainId}#${inTokenAddress}#${outTokenAddress}`,
      // parameters
      offerer: order.parameters.offerer,
      zone: order.parameters.zone,
      zoneHash: order.parameters.zoneHash,
      startTime: order.parameters.startTime,
      endTime: order.parameters.endTime,
      orderType: order.parameters.orderType,
      // offer
      offerItemType: order.parameters.offer[0].itemType,
      offerToken: order.parameters.offer[0].token,
      offerIdentifierOrCriteria: order.parameters.offer[0].identifierOrCriteria,
      offerStartAmount: order.parameters.offer[0].startAmount,
      offerEndAmount: order.parameters.offer[0].endAmount,
      // consideration
      considerationItemType: order.parameters.consideration[0].itemType,
      considerationToken: order.parameters.consideration[0].token,
      considerationIdentifierOrCriteria: order.parameters.consideration[0].identifierOrCriteria,
      considerationStartAmount: order.parameters.consideration[0].startAmount,
      considerationEndAmount: order.parameters.consideration[0].endAmount,
      considerationRecipient: order.parameters.consideration[0].recipient,
      // parameters
      totalOriginalConsiderationItems: order.parameters.totalOriginalConsiderationItems,
      salt: order.parameters.salt,
      conduitKey: order.parameters.conduitKey,
      counter: order.parameters.counter,
      // signature
      signature: order.signature,
    }
    // console.log('DEBUG limitOrder', limitOrder)
    await API.graphql(graphqlOperation(createOrder2, { input: limitOrder }))
  } catch (err) {
    console.log('Error creating limit order:', err)
  }

  // Create wallet if not exists
  // try {
  //   //
  //   const wallet = {
  //     chainId,
  //     address: account,
  //     chainIdAndAddress: `${chainId}#${account}`,
  //   }
  //   // console.log('DEBUG limitOrder', limitOrder)
  //   await API.graphql(
  //     graphqlOperation(createWalletIfNotExists, {
  //       input: wallet,
  //       condition: { chainIdAndAddress: { attributeExists: false } },
  //     })
  //   )
  // } catch (err) {
  //   console.log('error creating wallet:', err)
  // }

  return order
}
