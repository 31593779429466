import { parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
// import { OrderType } from 'state/swap/actions'

/**
 * Parses a CurrencyAmount from the passed string.
 * Returns the CurrencyAmount, or undefined if parsing fails.
 */
export default function tryParseCurrencyAmount<T extends Currency>(
  value?: string,
  currency?: T
): CurrencyAmount<T> | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  return undefined
}

// function tryParseCurrencyAmountWithOrderType<T extends Currency>(
//   orderType: OrderType,
//   value?: string,
//   currency?: T,
//   rateValue?: string
// ): CurrencyAmount<T> | undefined {
//   if (orderType === OrderType.MARKET) {
//     return tryParseCurrencyAmount(value, currency)
//   }
//   return undefined
// }
