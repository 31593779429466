/* eslint-disable import/no-unused-modules */
import { Trans } from '@lingui/macro'
import { sendAnalyticsEvent, Trace, TraceEvent } from '@uniswap/analytics'
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SwapEventName,
} from '@uniswap/analytics-events'
import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import LimitDetailsDropdown from 'components/limit/LimitDetailsDropdown'
// import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
// import TokenTable from 'components/Tokens/TokenTable/TokenTable'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { MouseoverTooltip } from 'components/Tooltip'
import Widget from 'components/Widget'
import { isSupportedChain } from 'constants/chains'
import { useSwapWidgetEnabled } from 'featureFlags/flags/swapWidget'
import useENSAddress from 'hooks/useENSAddress'
import useETH_ERC20Callback from 'hooks/useETH_ERC20Callback'
import usePermit2Allowance, { AllowanceState } from 'hooks/usePermit2Allowance'
import { useSwapCallback } from 'hooks/useSwapCallback'
import { useUSDPrice } from 'hooks/useUSDPrice'
import { createSellOrder } from 'hooks/utilities'
import JSBI from 'jsbi'
import { formatSwapQuoteReceivedEventProperties } from 'lib/utils/analytics'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { ArrowDown, Info } from 'react-feather'
import { useDispatch } from 'react-redux'
// import { ArrowDown, Info } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useToggleWalletModal } from 'state/application/hooks'
import {
  setInitialState as setInitialStateLimit,
  switchCurrencies as switchCurrenciesLimit,
  updateInputs,
} from 'state/limit/actions'
import { InterfaceTrade } from 'state/routing/types'
import { TradeState } from 'state/routing/types'
import styled, { useTheme } from 'styled-components/macro'
import invariant from 'tiny-invariant'
import { currencyAmountToPreciseFloat, formatTransactionAmount, priceToPreciseFloat } from 'utils/formatNumbers'
import { swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { GrayCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import SwapCurrencyInputPanel from '../../components/CurrencyInputPanel/SwapCurrencyInputPanel'
import ConfirmLimitModal from '../../components/limit/ConfirmLimitModal'
import Loader from '../../components/Loader'
import { AutoRow } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, PageWrapper, SwapCallbackError, SwapWrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { useDerivedLimitInfo } from '../../state/limit/hooks'
import { useLimitState } from '../../state/limit/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import { LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'
import { supportedChainId } from '../../utils/supportedChainId'

const ArrowContainer = styled.div`
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapSection = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: 12px;
  padding: 16px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.backgroundModule};
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`

const OutputSwapSection = styled(SwapSection)<{ showDetailsDropdown: boolean }>`
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundSurface}`};
  border-bottom-left-radius: ${({ showDetailsDropdown }) => showDetailsDropdown && '0'};
  border-bottom-right-radius: ${({ showDetailsDropdown }) => showDetailsDropdown && '0'};
`

const DetailsSwapSection = styled(SwapSection)`
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`

export function getIsValidSwapQuote(
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return !!swapInputError && !!trade && (tradeState === TradeState.VALID || tradeState === TradeState.SYNCING)
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const TRADE_STRING = 'SwapRouter'

export default function SwapReplica({ className }: { className?: string }) {
  const navigate = useNavigate()
  const { account, chainId, provider } = useWeb3React()
  const [isMarket, setIsMarket] = useState(true)
  const loadedUrlParams = useDefaultsFromURLSearch(isMarket)
  // const [typedInputValueLimit, setTypedInputValueLimit] = useState('')
  // const [typedOutputValueLimit, setTypedOutputValueLimit] = useState('')
  // const [typedRateValueLimit, setTypedRateValueLimit] = useState('')
  // const [, setLocalLimitValueModified] = useState(false)
  // const [localLimitValueInput, setLocalLimitValueInput] = useState('')
  // const [, setLocalLimitValueOutput] = useState('')
  const [shouldFetchMarketPrice, setShouldFetchMarketPrice] = useState(true)
  const [localLimitValueRate, setLocalLimitValueRate] = useState('')
  const [expiration, setExpiration] = useState(0)
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()
  const swapWidgetEnabled = useSwapWidgetEnabled()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.[Field.INPUT]?.currencyId),
    useCurrency(loadedUrlParams?.[Field.OUTPUT]?.currencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()
  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo()
  // limit state
  const { limitRate, isInitialState: isInitialStateLimit } = useLimitState()
  const {
    inputError: limitInputError,
    formattedAmountInput: formattedAmountLimitInput,
    formattedAmountOutput: formattedAmountLimitOutput,
    formattedAmountRate: formattedAmountLimitRate,
  } = useDerivedLimitInfo()
  const dispatch = useDispatch()
  // const { parsedInputAmountLimit, parsedOutputAmountLimit, parsedRateAmountLimit } = useDerivedLimitInfo()

  const { isETH_ERC20, nativeSymbol, wrapSymbol } = useETH_ERC20Callback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT]
  )

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )
  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT])

  // const parsedAmountsLimitOrder = useMemo(
  //   () => ({
  //     [FieldLimitOrder.INPUT]: independentFieldLimitOrder === FieldLimitOrder.INPUT ? parsedAmount : trade?.inputAmount,
  //     [FieldLimitOrder.OUTPUT]:
  //       independentFieldLimitOrder === FieldLimitOrder.INPUT ? parsedAmount : trade?.inputAmount,
  //     [FieldLimitOrder.RATE]: independentFieldLimitOrder === FieldLimitOrder.INPUT ? parsedAmount : trade?.inputAmount,
  //   }),
  //   [independentFieldLimitOrder, parsedAmount, trade]
  // )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount)
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount)
  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade
        ? undefined
        : computeFiatValuePriceImpact(fiatValueTradeInput.data, fiatValueTradeOutput.data),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  // const { onUserInputLimit, onUserInputLimitOutputOrRate } = useLimitActionHandlers()
  const isValid = !swapInputError
  const isValidLimit = !limitInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
  // const dependentFieldLimitOrder: FieldLimitOrder =
  //   typedInputValue === '' && typedOutputValue && rateValue
  //     ? FieldLimitOrder.INPUT
  //     : independentFieldLimitOrder === FieldLimitOrder.OUTPUT
  //     ? FieldLimitOrder.RATE
  //     : FieldLimitOrder.OUTPUT
  // const dependentFieldLimitOrder: FieldLimitOrder = typedRateValue ? FieldLimitOrder.OUTPUT : FieldLimitOrder.RATE

  // Swap
  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // Limit
  useEffect(() => {
    // !limitRate && !routeIsLoading && !routeIsSyncing
    // !limitRate &&
    //   isInitialStateLimit &&
    // if (isInitialStateLimit && !limitRate) {
    // if (!limitRate) {
    if (!limitRate) {
      // console.log('updating price with trade.executionPrice')
      // console.log('shouldFetchMarketPrice', shouldFetchMarketPrice)
      // console.log('limitOutput', limitOutput)
      // !limitRate &&
      shouldFetchMarketPrice &&
        dispatch(
          updateInputs({
            updatedField: 'limitRate',
            // newValue: formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)),
            // newValue: isInitialStateLimit ? priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '' : '',
            newValue: priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '',
          })
        )
      // setLocalLimitValueRate(priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '')
    }
  }, [dispatch, isInitialStateLimit, limitRate, shouldFetchMarketPrice, trade])

  // useEffect(() => {
  //   // When the user clears the rate input field, do something
  //   if (localLimitValueRate === '') {
  //     dispatch(
  //       updateInputs({
  //         updatedField: 'limitRate',
  //         // newValue: formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)),
  //         // newValue: isInitialStateLimit ? priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '' : '',
  //         newValue: '',
  //       })
  //     )
  //     setShouldFetchMarketPrice(false)
  //   }
  // }, [dispatch, localLimitValueRate])

  // useEffect(() => {
  //   // !limitRate && !routeIsLoading && !routeIsSyncing
  //   // !limitRate &&
  //   //   isInitialStateLimit &&
  //   if (limitInput) {
  //     // console.log('updating price with trade.executionPrice')
  //     // console.log('limitRate', limitRate)
  //     // !limitRate &&
  //     dispatch(
  //       setInitialStateLimit({
  //         newState: false,
  //       })
  //     )
  //   } else {
  //     dispatch(
  //       setInitialStateLimit({
  //         newState: true,
  //       })
  //     )
  //   }
  // }, [dispatch, isInitialStateLimit, limitInput, limitRate])

  const handleTypeInputLimit = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
      // onUserInputLimit(value)
      // setTypedInputValueLimit(value)
      // setLocalLimitValueInput(value)
      // If the user clears input field, reset should fetch market price to true
      !value && setShouldFetchMarketPrice(true)
      dispatch(updateInputs({ updatedField: 'limitInput', newValue: value }))
      dispatch(setInitialStateLimit({ newState: false }))
      // console.log('limitRate', limitRate)
      // !limitRate && !routeIsLoading && !routeIsSyncing
      // dispatch(
      //   updateInputs({
      //     updatedField: 'limitRate',
      //     // newValue: formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)),
      //     newValue: priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '',
      //   })
      // )
      // console.log('limitInput', limitInput)
      // console.log('limitOutput', limitOutput)
      // console.log('limitRate', limitRate)
      // dispatch(updateInputs({ updatedField: 'input1', newValue: value }))
      // if (!isMarket) {
      //   // onUserLimitOrderInput(FieldLimitOrder.INPUT, value)
      //   // If user hasn't input an exchange rate
      //   // set rate as the market rate
      //   // If user has input an exchange rate, and is changing inputValue
      //   // calculate and update outputValue accordingly
      //   if (!typedRateValueLimit) {
      //     // console.log('trade', trade)
      //     // setTypedRateValueLimit(formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)))
      //     // onUserInputLimitOutputOrRate(
      //     //   FieldLimit.RATE,
      //     //   formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice))
      //     // )
      //   } else {
      //     const calculatedOutputValue = formatTransactionAmount(
      //       parseFloat(value ?? '') * parseFloat(typedRateValueLimit)
      //     )
      //     // onUserInput(Field.OUTPUT, calculatedOutputValue)
      //     console.log('typedRateValueLimit', typedRateValueLimit)
      //     // setTypedOutputValueLimit(calculatedOutputValue)
      //     onUserInputLimitOutputOrRate(FieldLimit.OUTPUT, calculatedOutputValue)
      //   }
      // }
      // console.log('typing input, independentFieldLimitOrder =', independentFieldLimitOrder)
      // console.log('typing input, dependentFieldLimitOrder =', dependentFieldLimitOrder)
      // console.log('typing input, rate value', rateValue)
      // console.log('typing input, typed input value', value)
      // console.log('typing input, typed output value', typedOutputValueLimit)
      // console.log('typing input, rate value', typedRateValueLimit)
      // console.log('typing input, typed rate value', typedRateValue)
      // console.log(
      //   'typing input, output should show',
      //   formatTransactionAmount(parseFloat(rateValue ?? '') * parseFloat(value)),
      //   parseFloat(rateValue ?? '') * parseFloat(value)
      // )
    },
    [dispatch, onUserInput]
  )
  const handleTypeOutputLimit = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
      // setLocalLimitValueModified(true)
      // setLocalLimitValueOutput(value)
      setShouldFetchMarketPrice(false)
      dispatch(updateInputs({ updatedField: 'limitOutput', newValue: value }))
      // console.log('limitInput', limitInput)
      // console.log('limitOutput', limitOutput)
      // console.log('limitRate', limitRate)
      // onUserInputLimitOutputOrRate(FieldLimit.OUTPUT, value)
      // setTypedOutputValueLimit(value)
      // console.log('typing ouput, independentField =', independentField)
      // If user has input value, and is changing outputValue
      // set exchange rate as outputValue / inputValue
      // if (typedInputValueLimit) {
      //   setTypedRateValueLimit(formatTransactionAmount(parseFloat(value) / parseFloat(typedInputValueLimit)))
      // }
      // if (!isMarket) {
      // onUserLimitOrderInput(FieldLimitOrder.OUTPUT, value)
      // }
      // console.log('typing output, trade.inputAmount', trade?.inputAmount)
    },
    [dispatch, onUserInput]
  )
  const handleTypeRateLimit = useCallback(
    (value: string) => {
      // onUserRateInput(value)
      // setLocalLimitValueModified(true)
      setShouldFetchMarketPrice(false)
      setLocalLimitValueRate(value)
      dispatch(updateInputs({ updatedField: 'limitRate', newValue: value }))
      // console.log('limitInput', limitInput)
      // console.log('limitOutput', limitOutput)
      // console.log('limitRate', limitRate)
      // onUserInputLimitOutputOrRate(FieldLimit.RATE, value)
      // If user typed inputValue, calculate and update outputValue accordingly
      // if (typedInputValueLimit) {
      //   const calculatedOutputValue = formatTransactionAmount(
      //     parseFloat(value ?? '') * parseFloat(typedInputValueLimit)
      //   )
      //   // onUserInput(Field.OUTPUT, calculatedOutputValue)
      //   // onUserInputLimitOutputOrRate(FieldLimit.OUTPUT, calculatedOutputValue)
      //   setTypedOutputValueLimit(calculatedOutputValue)
      // }
      // console.log('typing rate, independentField =', independentField)
      // console.log('typing rate, rate value =', value)
      // onUserLimitOrderInput(FieldLimitOrder.RATE, value)
    },
    [dispatch]
  )
  const handleExpirationSelect = useCallback((value: number) => {
    // onExpirationSelection(value)
    setExpiration(value)
  }, [])

  const handleSetToMarketPrice = useCallback(() => {
    dispatch(
      updateInputs({
        updatedField: 'limitRate',
        // newValue: formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)),
        newValue: priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '',
      })
    )
  }, [dispatch, trade?.executionPrice])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const [{ showLimitConfirm, limitToConfirm, limitErrorMessage, attemptingLimit, limitHash }, setLimitState] =
    useState<{
      showLimitConfirm: boolean
      limitToConfirm: Trade<Currency, Currency, TradeType> | undefined
      attemptingLimit: boolean
      limitErrorMessage: string | undefined
      limitHash: string | undefined
    }>({
      showLimitConfirm: false,
      limitToConfirm: undefined,
      attemptingLimit: false,
      limitErrorMessage: undefined,
      limitHash: undefined,
    })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatTransactionAmount(currencyAmountToPreciseFloat(parsedAmounts[dependentField])),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  // const handleToggleLimitMarket = useCallback(() => {
  //   // If switching from limit to market
  //   if (!isMarket) {
  //     independentField === Field.OUTPUT && onUserInput(Field.INPUT, formattedAmounts[Field.INPUT])
  //   }
  // }, [formattedAmounts, independentField, isMarket, onUserInput])

  const formattedAmountsLimitOrder = useMemo(
    () => ({
      // ['INPUT']:
      //   limitOutput && !limitRate
      //     ? formatTransactionAmount(currencyAmountToPreciseFloat(trade?.inputAmount))
      //     : limitInput,
      // ['OUTPUT']:
      //   // limitInput && !limitRate && !localLimitValueModified
      //   limitInput && !limitRate && isEmptyLimit
      //     ? formatTransactionAmount(currencyAmountToPreciseFloat(trade?.outputAmount))
      //     : limitOutput,
      // ['RATE']:
      //   (limitInput && limitOutput === '' && isEmptyLimit) || (limitOutput && limitInput === '')
      //     ? formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice))
      //     : limitRate,
      // : formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)),
      // independentFieldLimitOrder === FieldLimitOrder.OUTPUT
      //   ? formatTransactionAmount(parseFloat(typedValue) / parseFloat(typedInputValueLimit)) ||
      //     formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice))
      //   : typedRateValueLimit || formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)),
      // [dependentFieldLimitOrder]:
      //   independentField === Field.OUTPUT
      //     ? formatTransactionAmount(parseFloat(typedValue) / parseFloat(typedInputValue))
      //     : formatTransactionAmount(parseFloat(typedRateValue ?? '') * parseFloat(typedInputValue)),
      // ['RATE']: formatTransactionAmount(currencyAmountToPreciseFloat(parsedAmountsLimitOrder['RATE'])),
      ['INPUT']: formattedAmountLimitInput,
      ['OUTPUT']: formattedAmountLimitOutput,
      ['RATE']: formattedAmountLimitRate,
    }),
    [formattedAmountLimitInput, formattedAmountLimitOutput, formattedAmountLimitRate]
  )

  // const formattedAmounts = useMemo(
  //   () => ({
  //     [independentField]: typedValue,
  //     [dependentField]: isMarket
  //       ? showWrap
  //         ? parsedAmounts[independentField]?.toExact() ?? ''
  //         : formatTransactionAmount(currencyAmountToPreciseFloat(parsedAmounts[dependentField]))
  //       : typedValue,
  //   }),
  //   [dependentField, independentField, parsedAmounts, showWrap, typedValue, isMarket]
  // )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const maximumAmountIn = useMemo(() => {
    const maximumAmountIn = trade?.maximumAmountIn(allowedSlippage)
    return maximumAmountIn?.currency.isToken ? (maximumAmountIn as CurrencyAmount<Token>) : undefined
  }, [allowedSlippage, trade])
  const allowance = usePermit2Allowance(
    isMarket,
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    isSupportedChain(chainId) ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined
  )
  const isApprovalLoading = allowance.state === AllowanceState.REQUIRED && allowance.isApprovalLoading
  const [isAllowancePending, setIsAllowancePending] = useState(false)
  const updateAllowance = useCallback(async () => {
    invariant(allowance.state === AllowanceState.REQUIRED)
    setIsAllowancePending(true)
    try {
      await allowance.approveAndPermit()
      sendAnalyticsEvent(InterfaceEventName.APPROVE_TOKEN_TXN_SUBMITTED, {
        chain_id: chainId,
        token_symbol: maximumAmountIn?.currency.symbol,
        token_address: maximumAmountIn?.currency.address,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setIsAllowancePending(false)
    }
  }, [allowance, chainId, maximumAmountIn?.currency.address, maximumAmountIn?.currency.symbol])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const swapFiatValues = useMemo(() => {
    return { amountIn: fiatValueTradeInput.data, amountOut: fiatValueTradeOutput.data }
  }, [fiatValueTradeInput, fiatValueTradeOutput])

  // the callback to execute the swap
  const { callback: swapCallback } = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
        sendEvent({
          category: 'Swap',
          action: 'transaction hash',
          label: hash,
        })
        sendEvent({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
            '/'
          ),
        })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [
    swapCallback,
    stablecoinPriceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
  ])

  // the callback to execute the limit order
  const handleLimit = useCallback(async () => {
    setLimitState({
      attemptingLimit: true,
      limitToConfirm,
      showLimitConfirm,
      limitErrorMessage: undefined,
      limitHash: undefined,
    })
    try {
      const sellOrder = await createSellOrder(
        account,
        chainId,
        provider,
        trade,
        expiration,
        // typedInputValueLimit,
        // typedOutputValueLimit
        // localLimitValueInput,
        // localLimitValueOutput
        formattedAmountsLimitOrder['INPUT'],
        formattedAmountsLimitOrder['OUTPUT']
      )
      setLimitState({
        attemptingLimit: false,
        limitToConfirm,
        showLimitConfirm,
        limitErrorMessage: undefined,
        limitHash: sellOrder.signature,
      })
    } catch (error) {
      setLimitState({
        attemptingLimit: false,
        limitToConfirm,
        showLimitConfirm,
        // limitErrorMessage: error.message,
        limitErrorMessage: Error(swapErrorToUserReadableMessage(error)).message,
        limitHash: undefined,
      })
    }
  }, [limitToConfirm, showLimitConfirm, account, chainId, provider, trade, expiration, formattedAmountsLimitOrder])

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleLimitConfirmDismiss = useCallback(() => {
    setLimitState({ showLimitConfirm: false, limitToConfirm, attemptingLimit, limitErrorMessage, limitHash })
    // if there was a tx hash, we want to clear the input
    if (limitHash) {
      onUserInput(Field.INPUT, '')
      dispatch(updateInputs({ updatedField: 'limitInput', newValue: '' }))
      dispatch(updateInputs({ updatedField: 'limitOutput', newValue: '' }))
    }
  }, [limitToConfirm, attemptingLimit, limitErrorMessage, limitHash, onUserInput, dispatch])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      // console.log('shouldFetchMarketPrice', priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6))
      shouldFetchMarketPrice &&
        dispatch(
          updateInputs({
            updatedField: 'limitRate',
            // newValue: formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)),
            // newValue: isInitialStateLimit ? priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '' : '',
            newValue: priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '',
          })
        )
    },
    [dispatch, onCurrencySelection, shouldFetchMarketPrice, trade?.executionPrice]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handleMaxInputLimit = useCallback(() => {
    if (maxInputAmount) {
      onUserInput(Field.INPUT, maxInputAmount.toExact())
      // setLocalLimitValueInput(maxInputAmount.toExact())
      dispatch(updateInputs({ updatedField: 'limitInput', newValue: maxInputAmount.toExact() }))
    }
  }, [dispatch, maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      // console.log('limitOutput', limitOutput)
      // console.log('trade.executionPrice', trade?.executionPrice)
      setShouldFetchMarketPrice(true)
      // !limitOutput &&
      //   dispatch(
      //     updateInputs({
      //       updatedField: 'limitRate',
      //       // newValue: formatTransactionAmount(priceToPreciseFloat(trade?.executionPrice)),
      //       // newValue: isInitialStateLimit ? priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '' : '',
      //       newValue: priceToPreciseFloat(trade?.executionPrice)?.toPrecision(6) ?? '',
      //     })
      //   )
    },
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time of receipt of latest swap quote.
      setSwapQuoteReceivedDate(now)
      // Log swap quote.
      sendAnalyticsEvent(
        SwapEventName.SWAP_QUOTE_RECEIVED,
        formatSwapQuoteReceivedEventProperties(trade, trade.gasUseEstimateUSD ?? undefined, fetchingSwapQuoteStartTime)
      )
      // Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  // Wei TODO (Low priority): This will always show DetailsDropdown for Market Tab, which should not be shown at the very beginning.
  // const showDetailsDropdownLimit = true

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <>
        <TokenSafetyModal
          isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
          tokenAddress={importTokensNotInDefault[0]?.address}
          secondTokenAddress={importTokensNotInDefault[1]?.address}
          onContinue={handleConfirmTokenWarning}
          onCancel={handleDismissTokenWarning}
          showCancel={true}
        />
        <PageWrapper>
          {swapWidgetEnabled ? (
            <Widget
              defaultTokens={{
                [Field.INPUT]: loadedInputCurrency ?? undefined,
                [Field.OUTPUT]: loadedOutputCurrency ?? undefined,
              }}
              width="100%"
            />
          ) : (
            <SwapWrapper chainId={chainId} className={className} id="swap-page">
              <SwapHeader
                isMarket={isMarket}
                setIsMarket={setIsMarket}
                allowedSlippage={allowedSlippage}
                inputValueLimit={formattedAmountsLimitOrder['INPUT']}
                inputValueMarket={formattedAmounts[Field.INPUT]}
              />
              {isMarket ? (
                <ConfirmSwapModal
                  isOpen={showConfirm}
                  trade={trade}
                  originalTrade={tradeToConfirm}
                  onAcceptChanges={handleAcceptChanges}
                  attemptingTxn={attemptingTxn}
                  txHash={txHash}
                  recipient={recipient}
                  allowedSlippage={allowedSlippage}
                  onConfirm={handleSwap}
                  swapErrorMessage={swapErrorMessage}
                  onDismiss={handleConfirmDismiss}
                  swapQuoteReceivedDate={swapQuoteReceivedDate}
                  fiatValueInput={fiatValueTradeInput}
                  fiatValueOutput={fiatValueTradeOutput}
                />
              ) : (
                <ConfirmLimitModal
                  isOpen={showLimitConfirm}
                  attemptingLimit={attemptingLimit}
                  trade={trade}
                  // limitOrderInputValue={formattedAmountsLimitOrder['INPUT'] || formattedAmounts[Field.INPUT]}
                  // limitOrderOutputValue={formattedAmountsLimitOrder['OUTPUT'] || formattedAmounts[Field.OUTPUT]}
                  limitOrderValueInput={formattedAmountsLimitOrder['INPUT']}
                  limitOrderValueOutput={formattedAmountsLimitOrder['OUTPUT']}
                  limitOrderValuePrice={formattedAmountsLimitOrder['RATE']}
                  limitHash={limitHash}
                  limitErrorMessage={limitErrorMessage}
                  onConfirm={handleLimit}
                  onDismiss={handleLimitConfirmDismiss}
                />
              )}
              <div style={{ display: 'relative' }}>
                <SwapSection>
                  <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
                    {isMarket ? (
                      <SwapCurrencyInputPanel
                        label={
                          independentField === Field.OUTPUT && !showWrap ? (
                            <Trans>From (at most)</Trans>
                          ) : (
                            <Trans>From</Trans>
                          )
                        }
                        value={formattedAmounts[Field.INPUT]}
                        showMaxButton={showMaxButton}
                        currency={currencies[Field.INPUT] ?? null}
                        onUserInput={handleTypeInput}
                        onMax={handleMaxInput}
                        fiatValue={fiatValueInput ?? undefined}
                        onCurrencySelect={handleInputSelect}
                        otherCurrency={currencies[Field.OUTPUT]}
                        showCommonBases={true}
                        id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                        loading={independentField === Field.OUTPUT && routeIsSyncing}
                      />
                    ) : (
                      <SwapCurrencyInputPanel
                        label={
                          independentField === Field.OUTPUT && !showWrap ? (
                            <Trans>From (at most)</Trans>
                          ) : (
                            <Trans>From</Trans>
                          )
                        }
                        // value={formattedAmountsLimitOrder['INPUT'] || formattedAmounts[Field.INPUT]}
                        value={showWrap ? formattedAmounts[Field.INPUT] : formattedAmountsLimitOrder['INPUT']}
                        showMaxButton={showMaxButton}
                        currency={currencies[Field.INPUT] ?? null}
                        onUserInput={handleTypeInputLimit}
                        onMax={handleMaxInputLimit}
                        fiatValue={fiatValueInput ?? undefined}
                        onCurrencySelect={handleInputSelect}
                        otherCurrency={currencies[Field.OUTPUT]}
                        showCommonBases={true}
                        id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                        loading={independentField === Field.OUTPUT && routeIsSyncing}
                      />
                    )}
                  </Trace>
                </SwapSection>
                <ArrowWrapper clickable={isSupportedChain(chainId)}>
                  <TraceEvent
                    events={[BrowserEvent.onClick]}
                    name={SwapEventName.SWAP_TOKENS_REVERSED}
                    element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
                  >
                    <ArrowContainer
                      onClick={() => {
                        onSwitchTokens()
                        // !isMarket && dispatch(switchCurrenciesLimit())
                        dispatch(switchCurrenciesLimit())
                      }}
                      color={theme.textPrimary}
                    >
                      <ArrowDown
                        size="16"
                        color={
                          currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.textPrimary : theme.textTertiary
                        }
                      />
                    </ArrowContainer>
                  </TraceEvent>
                </ArrowWrapper>
              </div>
              <AutoColumn gap="md">
                <div>
                  <OutputSwapSection showDetailsDropdown={!isMarket || showDetailsDropdown}>
                    <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
                      {isMarket ? (
                        <SwapCurrencyInputPanel
                          value={formattedAmounts[Field.OUTPUT]}
                          onUserInput={handleTypeOutput}
                          label={
                            independentField === Field.INPUT && !showWrap ? (
                              <Trans>To (at least)</Trans>
                            ) : (
                              <Trans>To</Trans>
                            )
                          }
                          showMaxButton={false}
                          hideBalance={false}
                          fiatValue={fiatValueOutput ?? undefined}
                          priceImpact={stablecoinPriceImpact}
                          currency={currencies[Field.OUTPUT] ?? null}
                          onCurrencySelect={handleOutputSelect}
                          otherCurrency={currencies[Field.INPUT]}
                          showCommonBases={true}
                          id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                          loading={independentField === Field.INPUT && routeIsSyncing}
                        />
                      ) : (
                        <SwapCurrencyInputPanel
                          // value={formattedAmounts[Field.OUTPUT]}
                          value={
                            // isMarket ? formattedAmounts[Field.OUTPUT] : formattedAmountsLimitOrder[Field.OUTPUT]
                            // formattedAmountsLimitOrder['OUTPUT'] || formattedAmounts[Field.OUTPUT]
                            showWrap ? formattedAmounts[Field.OUTPUT] : formattedAmountsLimitOrder['OUTPUT']
                            // limitOutput
                            // formattedAmountsLimitOrder['OUTPUT']
                            // : typedRateValueLimit // : typedRateValue
                            // ? formattedAmountsLimitOrder[FieldLimitOrder.OUTPUT] ?? ''
                            // : formattedAmounts[Field.OUTPUT]
                            // isMarket ? formattedAmounts[Field.OUTPUT] : formattedAmountsLimitOrder[FieldLimitOrder.OUTPUT]
                          }
                          onUserInput={handleTypeOutputLimit}
                          label={
                            independentField === Field.INPUT && !showWrap ? (
                              <Trans>To (at least)</Trans>
                            ) : (
                              <Trans>To</Trans>
                            )
                          }
                          showMaxButton={false}
                          hideBalance={false}
                          fiatValue={fiatValueOutput ?? undefined}
                          priceImpact={stablecoinPriceImpact}
                          currency={currencies[Field.OUTPUT] ?? null}
                          onCurrencySelect={handleOutputSelect}
                          otherCurrency={currencies[Field.INPUT]}
                          showCommonBases={true}
                          id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                          loading={independentField === Field.INPUT && routeIsSyncing}
                        />
                      )}
                    </Trace>

                    {isMarket && recipient !== null && !showWrap ? (
                      <>
                        <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                          <ArrowWrapper clickable={false}>
                            <ArrowDown size="16" color={theme.textSecondary} />
                          </ArrowWrapper>
                          <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                            <Trans>- Remove recipient</Trans>
                          </LinkStyledButton>
                        </AutoRow>
                        <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                      </>
                    ) : null}
                  </OutputSwapSection>
                  {isMarket ? (
                    showDetailsDropdown && (
                      <DetailsSwapSection>
                        <SwapDetailsDropdown
                          trade={trade}
                          syncing={routeIsSyncing}
                          loading={routeIsLoading}
                          allowedSlippage={allowedSlippage}
                        />
                      </DetailsSwapSection>
                    )
                  ) : (
                    <DetailsSwapSection>
                      <LimitDetailsDropdown
                        // value={typedRateValue!}
                        // value={formattedAmountsLimitOrder[FieldLimitOrder.RATE]}
                        value={formattedAmountsLimitOrder['RATE']}
                        // value={localLimitValueRate}
                        // value={limitRate}
                        onUserInput={handleTypeRateLimit}
                        onExpirationSelect={handleExpirationSelect}
                        onSetToMarket={handleSetToMarketPrice}
                        trade={trade}
                        syncing={routeIsSyncing}
                        loading={routeIsLoading}
                        isOpen={!showWrap && formattedAmountLimitInput !== ''}
                        // isOpen={!showWrap && (limitInput !== '' || limitOutput !== '')}
                      />
                    </DetailsSwapSection>
                  )}
                </div>
                {isMarket && showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
                <div>
                  {swapIsUnsupported ? (
                    <ButtonPrimary disabled={true}>
                      <ThemedText.DeprecatedMain mb="4px">
                        <Trans>Unsupported Asset</Trans>
                      </ThemedText.DeprecatedMain>
                    </ButtonPrimary>
                  ) : !account ? (
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                      properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                      element={InterfaceElementName.CONNECT_WALLET_BUTTON}
                    >
                      <ButtonLight onClick={toggleWalletModal} fontWeight={600}>
                        <Trans>Connect Wallet</Trans>
                      </ButtonLight>
                    </TraceEvent>
                  ) : !isMarket && chainId === 42220 ? (
                    <GrayCard style={{ textAlign: 'center' }}>
                      <ThemedText.DeprecatedMain mb="4px">
                        <Trans>Celo mainnet is unsupported for limit order</Trans>
                      </ThemedText.DeprecatedMain>
                    </GrayCard>
                  ) : showWrap ? (
                    <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap} fontWeight={600}>
                      {wrapInputError ? (
                        <WrapErrorText wrapInputError={wrapInputError} />
                      ) : wrapType === WrapType.WRAP ? (
                        <Trans>Wrap</Trans>
                      ) : wrapType === WrapType.UNWRAP ? (
                        <Trans>Unwrap</Trans>
                      ) : null}
                    </ButtonPrimary>
                  ) : !isMarket && isETH_ERC20 ? (
                    <GrayCard style={{ textAlign: 'center' }}>
                      <ThemedText.DeprecatedMain mb="4px">
                        <Trans>{`Wrap ${nativeSymbol} to ${wrapSymbol} for limit order`}</Trans>
                      </ThemedText.DeprecatedMain>
                    </GrayCard>
                  ) : isMarket && routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                    <GrayCard style={{ textAlign: 'center' }}>
                      <ThemedText.DeprecatedMain mb="4px">
                        <Trans>Insufficient liquidity for this trade</Trans>
                      </ThemedText.DeprecatedMain>
                    </GrayCard>
                  ) : isValid && allowance.state === AllowanceState.REQUIRED ? (
                    <ButtonPrimary
                      onClick={updateAllowance}
                      disabled={isAllowancePending || isApprovalLoading}
                      style={{ gap: 14 }}
                    >
                      {isAllowancePending ? (
                        <>
                          <Loader size="20px" />
                          <Trans>Approve in your wallet</Trans>
                        </>
                      ) : isApprovalLoading ? (
                        <>
                          <Loader size="20px" />
                          <Trans>Approval pending</Trans>
                        </>
                      ) : (
                        <>
                          <div style={{ height: 20 }}>
                            <MouseoverTooltip
                              text={
                                isMarket ? (
                                  <Trans>
                                    Permission is required for WeiX to swap each token. This will expire after one month
                                    for your security.
                                  </Trans>
                                ) : (
                                  <Trans>Permission is required for WeiX to swap each token.</Trans>
                                )
                              }
                            >
                              <Info size={20} />
                            </MouseoverTooltip>
                          </div>
                          <Trans>Approve use of {currencies[Field.INPUT]?.symbol}</Trans>
                        </>
                      )}
                    </ButtonPrimary>
                  ) : isMarket ? (
                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap()
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined,
                          })
                        }
                      }}
                      id="swap-button"
                      disabled={
                        !isValid ||
                        routeIsSyncing ||
                        routeIsLoading ||
                        priceImpactTooHigh ||
                        allowance.state !== AllowanceState.ALLOWED
                      }
                      error={isValid && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED}
                    >
                      <Text fontSize={20} fontWeight={600}>
                        {swapInputError ? (
                          swapInputError
                        ) : routeIsSyncing || routeIsLoading ? (
                          <Trans>Swap</Trans>
                        ) : priceImpactTooHigh ? (
                          <Trans>Price Impact Too High</Trans>
                        ) : priceImpactSeverity > 2 ? (
                          <Trans>Swap Anyway</Trans>
                        ) : (
                          <Trans>Swap</Trans>
                        )}
                      </Text>
                    </ButtonError>
                  ) : (
                    <ButtonError
                      onClick={() => {
                        setLimitState({
                          limitToConfirm: trade,
                          attemptingLimit: false,
                          limitErrorMessage: undefined,
                          showLimitConfirm: true,
                          limitHash: undefined,
                        })
                      }}
                      id="swap-button-limit"
                      disabled={
                        !isValidLimit ||
                        routeIsSyncing ||
                        routeIsLoading ||
                        // priceImpactTooHigh ||
                        // (permit2Enabled ? allowance.state !== AllowanceState.ALLOWED : Boolean(swapCallbackError))
                        allowance.state !== AllowanceState.ALLOWED
                      }
                    >
                      <Text fontSize={20} fontWeight={600}>
                        {limitInputError ? limitInputError : <Trans>Review Limit Order</Trans>}
                      </Text>
                    </ButtonError>
                  )}
                  {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                </div>
              </AutoColumn>
            </SwapWrapper>
          )}
          {/* <NetworkAlert /> */}
        </PageWrapper>
        <SwitchLocaleLink />
        {!swapIsUnsupported ? null : (
          <UnsupportedCurrencyFooter
            show={swapIsUnsupported}
            currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
          />
        )}
      </>
    </Trace>
  )
}
