/* eslint-disable import/no-unused-modules */
const chainId_link = new Map<number | undefined, string>([
  [3, 'ropsten'],
  [4, 'rinkeby'],
  [5, 'goerli'],
  [42, 'kovan'],
])

export function isEthChain(chainId: number | undefined) {
  if (chainId === 1) {
    return true
  } // Mainnet
  if (chainId === 3) {
    return true
  } // Ropsten
  if (chainId === 4) {
    return true
  } // Rinkeby
  if (chainId === 5) {
    return true
  } // Goerli
  if (chainId === 42) {
    return true
  } // Kovan
  return false
}

export function getEtherscanUrl(chainId: number | undefined, type: 'address' | 'tx', hash?: string) {
  if (isEthChain(chainId)) {
    if (chainId === 1) {
      return `https://etherscan.io/${type}/${hash}`
    }
    return `https://${chainId_link.get(chainId)}.etherscan.io/${type}/${hash}`
  }

  // BSC
  if (chainId === 56) {
    return `https://bscscan.com/${type}/${hash}`
  }
  if (chainId === 97) {
    return `https://testnet.bscscan.com/${type}/${hash}`
  }

  // Polygon
  if (chainId === 137) {
    return `https://polygonscan.com/${type}/${hash}`
  }
  if (chainId === 80001) {
    return `https://mumbai.polygonscan.com/${type}/${hash}`
  }

  // Avalanche
  if (chainId === 43114) {
    return `https://snowtrace.io/${type}/${hash}`
  }
  if (chainId === 43113) {
    return `https://testnet.snowtrace.io/${type}/${hash}`
  }

  // Fantom
  if (chainId === 250) {
    return `https://ftmscan.com/${type}/${hash}`
  }
  if (chainId === 4002) {
    return `https://testnet.ftmscan.com/${type}/${hash}`
  }

  // Heco
  if (chainId === 128) {
    return `https://hecoinfo.com/${type}/${hash}`
  }
  if (chainId === 256) {
    return `https://testnet.hecoinfo.com/${type}/${hash}`
  }

  // Cronos
  if (chainId === 25) {
    return `https://cronoscan.com/${type}/${hash}`
  }

  // Klaytn
  if (chainId === 8217) {
    return `https://scope.klaytn.com/${type}/${hash}`
  }
  if (chainId === 1001) {
    return `https://baobab.scope.klaytn.com/${type}/${hash}`
  }

  // Gnosis
  if (chainId === 100) {
    return `https://gnosisscan.io/${type}/${hash}`
  }

  // Aurora
  if (chainId === 1313161554) {
    return `https://aurorascan.dev/${type}/${hash}`
  }

  // Celo
  if (chainId === 42220) {
    return `https://celoscan.io/${type}/${hash}`
  }

  // Fusion
  if (chainId === 32659) {
    if (type === 'tx') {
      return `https://blocks.fusionnetwork.io/#!/transaction/${hash}`
    }
    return `https://blocks.fusionnetwork.io/#!/${type}/${hash}`
  }

  // Moonbeam
  if (chainId === 1284) {
    return `https://moonscan.io/${type}/${hash}`
  }
  if (chainId === 1285) {
    return `https://moonriver.moonscan.io/${type}/${hash}`
  }
  if (chainId === 1287) {
    return `https://moonbase.moonscan.io/${type}/${hash}`
  }

  // Optimism
  if (chainId === 10) {
    return `https://optimistic.etherscan.io/${type}/${hash}`
  }
  if (chainId === 420) {
    return `https://blockscout.com/optimism/goerli/${type}/${hash}`
  }

  // Arbitrum
  if (chainId === 42161) {
    return `https://arbiscan.io/${type}/${hash}`
  }
  if (chainId === 421611) {
    return `https://testnet.arbiscan.io/${type}/${hash}`
  }

  // ETHW
  if (chainId === 10001) {
    return `https://www.oklink.com/en/ethw/${type}/${hash}`
  }

  // ETHF
  if (chainId === 513100) {
    return `https://explorer.etherfair.org/${type}/${hash}`
  }

  return ''
}
