import { Trans } from '@lingui/macro'
import { Trace } from '@uniswap/analytics'
import { InterfaceModalName } from '@uniswap/analytics-events'
import { Currency, TradeType } from '@uniswap/sdk-core'
import { ReactNode, useCallback, useState } from 'react'
import { InterfaceTrade } from 'state/routing/types'

import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from '../TransactionConfirmationModal'
import LimitModalFooter from './LimitModalFooter'
import LimitModalHeader from './LimitModalHeader'

// eslint-disable-next-line import/no-unused-modules
export default function ConfirmLimitModal({
  isOpen,
  attemptingLimit,
  trade,
  limitOrderValueInput,
  limitOrderValueOutput,
  limitOrderValuePrice,
  limitHash,
  limitErrorMessage,
  onConfirm,
  onDismiss,
}: {
  isOpen: boolean
  attemptingLimit: boolean
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
  limitOrderValueInput: string
  limitOrderValueOutput: string
  limitOrderValuePrice: string
  limitHash: string | undefined
  limitErrorMessage: ReactNode | undefined
  onConfirm: () => void
  onDismiss: () => void
}) {
  // shouldLogModalCloseEvent lets the child LimitModalHeader component know when modal has been closed
  // and an event triggered by modal closing should be logged.
  const [shouldLogModalCloseEvent, setShouldLogModalCloseEvent] = useState(false)

  const onModalDismiss = useCallback(() => {
    if (isOpen) setShouldLogModalCloseEvent(true)
    onDismiss()
  }, [isOpen, onDismiss])

  const modalHeader = useCallback(() => {
    return trade ? (
      <LimitModalHeader
        trade={trade}
        limitOrderValueInput={limitOrderValueInput}
        limitOrderValueOutput={limitOrderValueOutput}
        limitOrderValuePrice={limitOrderValuePrice}
        shouldLogModalCloseEvent={shouldLogModalCloseEvent}
        setShouldLogModalCloseEvent={setShouldLogModalCloseEvent}
      />
    ) : null
  }, [trade, limitOrderValueInput, limitOrderValueOutput, limitOrderValuePrice, shouldLogModalCloseEvent])

  const modalBottom = useCallback(() => {
    return trade ? <LimitModalFooter onConfirm={onConfirm} limitErrorMessage={limitErrorMessage} /> : null
  }, [trade, onConfirm, limitErrorMessage])

  // text to show while loading
  // const pendingText = (
  //   <Trans>
  //     Limit order: {trade?.inputAmount?.toSignificant(6)} {trade?.inputAmount?.currency?.symbol} to{' '}
  //     {trade?.outputAmount?.toSignificant(6)} {trade?.outputAmount?.currency?.symbol}
  //   </Trans>
  // )
  const pendingText = (
    <Trans>
      Limit order: {limitOrderValueInput} {trade?.inputAmount?.currency?.symbol} to {limitOrderValueOutput}{' '}
      {trade?.outputAmount?.currency?.symbol}
    </Trans>
  )

  const confirmationContent = useCallback(
    () =>
      limitErrorMessage ? (
        <TransactionErrorContent onDismiss={onModalDismiss} message={limitErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title={<Trans>Confirm Limit Order</Trans>}
          onDismiss={onModalDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onModalDismiss, modalBottom, modalHeader, limitErrorMessage]
  )

  return (
    <Trace modal={InterfaceModalName.CONFIRM_SWAP}>
      <TransactionConfirmationModal
        isOpen={isOpen}
        onDismiss={onModalDismiss}
        attemptingTxn={attemptingLimit}
        hash={limitHash}
        content={confirmationContent}
        pendingText={pendingText}
        currencyToAdd={trade?.outputAmount.currency}
        isMarket={false}
      />
    </Trace>
  )
}
