/* eslint-disable import/no-unused-modules */
import { filterTimeAtom, OrderSortMethod, sortAscendingAtom, sortMethodAtom } from 'components/Orders/state'
import gql from 'graphql-tag'
import { useLimitOrders } from 'hooks/useLimitOrders'
import { useAtomValue } from 'jotai/utils'
import { useMemo } from 'react'

import { Chain, TopTokens100Query, useTopTokens100Query } from './__generated__/types-and-hooks'
import { CHAIN_NAME_TO_CHAIN_ID, PollingInterval, toHistoryDuration, usePollQueryWhileMounted } from './util'

gql`
  query TopTokens100($duration: HistoryDuration!, $chain: Chain!) {
    topTokens(pageSize: 100, page: 1, chain: $chain, orderBy: VOLUME) {
      id
      name
      chain
      address
      symbol
      standard
      market(currency: USD) {
        id
        totalValueLocked {
          id
          value
          currency
        }
        price {
          id
          value
          currency
        }
        pricePercentChange(duration: $duration) {
          id
          currency
          value
        }
        volume(duration: $duration) {
          id
          value
          currency
        }
      }
      project {
        id
        logoUrl
      }
    }
  }
`

function useSortedOrders(orders: SeaportOrder[]) {
  const sortMethod = useAtomValue(sortMethodAtom)
  const sortAscending = useAtomValue(sortAscendingAtom)

  return useMemo(() => {
    if (!orders) return undefined
    let orderArray = Array.from(orders)
    switch (sortMethod) {
      case OrderSortMethod.CREATED:
        orderArray = orderArray.sort(
          (a, b) => (parseInt(b?.parameters?.startTime) ?? 0) - (parseInt(a?.parameters?.startTime) ?? 0)
        )
        break
      case OrderSortMethod.EXPIRATION:
        orderArray = orderArray.sort(
          (a, b) => (parseInt(b?.parameters?.endTime) ?? 0) - (parseInt(a?.parameters?.endTime) ?? 0)
        )
        break
    }
    return sortAscending ? orderArray.reverse() : orderArray
  }, [orders, sortMethod, sortAscending])
}

// Number of items to render in each fetch in infinite scroll.
export const PAGE_SIZE = 20
export type TopToken = NonNullable<NonNullable<TopTokens100Query>['topTokens']>[number]

interface UseOrdersReturnValue {
  activeTokens: any
  historicalTokens: any
  orderSortRank: Record<string, number>
  loadingTokens: boolean
}

export interface SeaportOrder {
  chainId: number
  weixOrderId: string
  parameters: SeaportOrderParams
  signature: string
  txHash?: string
}

interface SeaportOrderParams {
  offerer: string
  zone: string
  zoneHash: string
  startTime: string
  endTime: string
  orderType: number
  offer: [SeaportOffer]
  consideration: [SeaportConsideration]
  totalOriginalConsiderationItems: number
  salt: string
  conduitKey: string
  counter: number
  isCancelled: boolean
  totalFilled: string
  totalSize: string
}

interface SeaportOffer {
  itemType: number
  token: string
  symbol: string
  decimals: number
  identifierOrCriteria: string
  startAmount: string
  endAmount: string
}

interface SeaportConsideration {
  itemType: number
  token: string
  symbol: string
  decimals: number
  identifierOrCriteria: string
  startAmount: string
  endAmount: string
  recipient: string
}

export function useOrders(chain: Chain, account: string): UseOrdersReturnValue {
  const chainId = CHAIN_NAME_TO_CHAIN_ID[chain]
  const duration = toHistoryDuration(useAtomValue(filterTimeAtom))
  const { activeOrders, historicalOrders } = useLimitOrders(chainId ?? 1, account)

  const formattedActiveOrders = useMemo(() => {
    const seaportOrders: SeaportOrder[] = activeOrders?.map((item) => {
      return {
        chainId: item.chainId,
        weixOrderId: item.orderId,
        parameters: {
          offerer: item.offerer,
          isCancelled: item.isCancelled,
          totalFilled: item.totalFilled,
          totalSize: item.totalSize,
          zone: item.zone,
          zoneHash: item.zoneHash,
          startTime: item.startTime,
          endTime: item.endTime,
          orderType: item.orderType,
          offer: [
            {
              itemType: item.offerItemType,
              token: item.offerToken,
              symbol: item.inSymbol,
              decimals: item.inDecimals,
              identifierOrCriteria: item.offerIdentifierOrCriteria,
              startAmount: item.offerStartAmount,
              endAmount: item.offerEndAmount,
            },
          ],
          consideration: [
            {
              itemType: item.considerationItemType,
              token: item.considerationToken,
              symbol: item.outSymbol,
              decimals: item.outDecimals,
              identifierOrCriteria: item.considerationIdentifierOrCriteria,
              startAmount: item.considerationStartAmount,
              endAmount: item.considerationEndAmount,
              recipient: item.considerationRecipient,
            },
          ],
          totalOriginalConsiderationItems: item.totalOriginalConsiderationItems,
          salt: item.salt,
          conduitKey: item.conduitKey,
          counter: item.counter,
        },
        signature: item.signature,
      }
    })
    return seaportOrders
  }, [activeOrders])

  const formattedHistoricalOrders = useMemo(() => {
    const seaportOrders: SeaportOrder[] = historicalOrders?.map((item) => {
      return {
        chainId: item.chainId,
        weixOrderId: item.orderId,
        parameters: {
          offerer: item.offerer,
          isCancelled: item.isCancelled,
          totalFilled: item.totalFilled,
          totalSize: item.totalSize,
          zone: item.zone,
          zoneHash: item.zoneHash,
          startTime: item.startTime,
          endTime: item.endTime,
          orderType: item.orderType,
          offer: [
            {
              itemType: item.offerItemType,
              token: item.offerToken,
              symbol: item.inSymbol,
              decimals: item.inDecimals,
              identifierOrCriteria: item.offerIdentifierOrCriteria,
              startAmount: item.offerStartAmount,
              endAmount: item.offerEndAmount,
            },
          ],
          consideration: [
            {
              itemType: item.considerationItemType,
              token: item.considerationToken,
              symbol: item.outSymbol,
              decimals: item.outDecimals,
              identifierOrCriteria: item.considerationIdentifierOrCriteria,
              startAmount: item.considerationStartAmount,
              endAmount: item.considerationEndAmount,
              recipient: item.considerationRecipient,
            },
          ],
          totalOriginalConsiderationItems: item.totalOriginalConsiderationItems,
          salt: item.salt,
          conduitKey: item.conduitKey,
          counter: item.counter,
        },
        signature: item.signature,
        txHash: item.txHash,
      }
    })
    return seaportOrders
  }, [historicalOrders])

  const { loading: loadingTokens } = usePollQueryWhileMounted(
    useTopTokens100Query({
      variables: { duration, chain },
    }),
    PollingInterval.Fast
  )

  const sortedActiveOrders = useSortedOrders(formattedActiveOrders)
  const sortedHistoricalOrders = useSortedOrders(formattedHistoricalOrders)
  const orderSortRank = useMemo(
    () =>
      sortedActiveOrders?.reduce((acc, cur, i) => {
        if (!cur.parameters.endTime) return acc
        return {
          ...acc,
          [cur.parameters.endTime]: i + 1,
        }
      }, {}) ?? {},
    [sortedActiveOrders]
  )

  return useMemo(
    () => ({
      activeTokens: sortedActiveOrders,
      historicalTokens: sortedHistoricalOrders,
      orderSortRank,
      loadingTokens,
    }),
    [sortedActiveOrders, sortedHistoricalOrders, orderSortRank, loadingTokens]
  )
}
