import type { Web3Provider } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { OpacityHoverState } from 'components/Common'
import { MouseoverTooltip } from 'components/Tooltip'
import { PAGE_SIZE, useOrders } from 'graphql/data/Orders'
// import { validateUrlChainParam } from 'graphql/data/util'
// import { SupportedChainId } from 'constants/chains'
import { CHAIN_ID_TO_BACKEND_NAME } from 'graphql/data/util'
import { ReactNode, useState } from 'react'
// import { useEffect } from 'react'
// import { AlertTriangle } from 'react-feather'
// import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { MAX_WIDTH_MEDIA_BREAKPOINT } from '../constants'
import { HeaderRow, LoadedRow, LoadingRow } from './OrderRow'

const OrderTitleContainer = styled.div`
  margin-bottom: 32px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
`

const Filter = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  border-radius: 16px;
  padding: 4px;
`

const Selector = styled.div<{ active: boolean }>`
  padding: 7px 10px;
  border-radius: 12px;
  background: ${({ active, theme }) => (active ? theme.backgroundInteractive : 'none')};
  cursor: pointer;

  ${OpacityHoverState}
`

const StyledSelectorText = styled(ThemedText.SubHeaderSmall)<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.textPrimary : theme.textSecondary)};
`

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  background-color: ${({ theme }) => theme.backgroundSurface};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
`

const TokenDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoOrdersState({ message }: { message: ReactNode }) {
  return (
    // <GridContainer>
    //   <HeaderRow />
    //   <NoTokenDisplay>{message}</NoTokenDisplay>
    // </GridContainer>
    <NoTokenDisplay>{message}</NoTokenDisplay>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingOrderTable({ isActive, rowCount = PAGE_SIZE }: { isActive: boolean; rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow isActive={isActive} />
      <TokenDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TokenDataContainer>
    </GridContainer>
  )
}

interface OrderTableProps {
  chainId: number | undefined
  account: string | undefined
  provider: Web3Provider | undefined
}

// eslint-disable-next-line import/no-unused-modules
export default function OrderTable(props: OrderTableProps) {
  const { chainId, account, provider } = props
  // const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)
  const chainName = CHAIN_ID_TO_BACKEND_NAME[chainId ?? 1]
  const {
    activeTokens: activeOrders,
    historicalTokens: historicalOrders,
    orderSortRank,
    loadingTokens,
  } = useOrders(chainName, account ?? '0x')
  const [isActive, setIsActive] = useState(true)

  // useEffect(() => {
  //   console.log('chainName', chainName)
  //   console.log('account', account)
  //   console.log('orders', orders)
  // }, [chainName, account])

  /* loading and error state */
  if (loadingTokens && !activeOrders && !historicalOrders) {
    return <LoadingOrderTable isActive={isActive} rowCount={PAGE_SIZE} />
    // } else if (!activeOrders && !historicalOrders) {
    //   return (
    //     <NoOrdersState
    //       message={
    //         <>
    //           <AlertTriangle size={16} />
    //           <Trans>An error occurred loading limit orders. Please try again.</Trans>
    //         </>
    //       }
    //     />
    //   )
    // } else if (activeOrders?.length === 0 || historicalOrders?.length === 0) {
    //   return <NoOrdersState message={<Trans>No limit orders found</Trans>} />
  } else {
    return (
      <>
        <OrderTitleContainer>
          <MouseoverTooltip
            text={<Trans>This table contains your active and historical limit orders, sorted in time.</Trans>}
            placement="bottom"
          >
            <ThemedText.HeadlineMedium>
              <Trans>Limit orders</Trans>
            </ThemedText.HeadlineMedium>
          </MouseoverTooltip>
          <Filter onClick={() => setIsActive(!isActive)}>
            <Selector active={isActive}>
              <StyledSelectorText lineHeight="20px" active={isActive}>
                Active
              </StyledSelectorText>
            </Selector>
            <Selector active={!isActive}>
              <StyledSelectorText lineHeight="20px" active={!isActive}>
                Historical
              </StyledSelectorText>
            </Selector>
          </Filter>
        </OrderTitleContainer>

        <GridContainer>
          <HeaderRow isActive={isActive} />
          <TokenDataContainer>
            {isActive ? (
              !activeOrders || activeOrders?.length === 0 ? (
                <NoOrdersState message={<Trans>No active limit orders found</Trans>} />
              ) : (
                activeOrders?.map((order: any, index: any) => (
                  <LoadedRow
                    key={order.weixOrderId}
                    tokenListIndex={index}
                    tokenListLength={activeOrders.length}
                    token={order}
                    sortRank={orderSortRank[order.parameters.salt]}
                    account={account}
                    provider={provider}
                    isActive={isActive}
                  />
                ))
              )
            ) : !historicalOrders || historicalOrders?.length === 0 ? (
              <NoOrdersState message={<Trans>No historical limit orders found</Trans>} />
            ) : (
              historicalOrders?.map((order: any, index: any) => (
                <LoadedRow
                  key={order.weixOrderId}
                  tokenListIndex={index}
                  tokenListLength={historicalOrders.length}
                  token={order}
                  sortRank={orderSortRank[order.parameters.salt]}
                  account={account}
                  provider={provider}
                  isActive={isActive}
                />
              ))
            )}
          </TokenDataContainer>
        </GridContainer>
      </>
    )
  }
}
