import { createAction } from '@reduxjs/toolkit'

export enum FieldLimit {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
  RATE = 'RATE',
}

interface UpdateInputsPayload {
  updatedField: 'limitInput' | 'limitOutput' | 'limitRate'
  newValue: string
}

// export const typeInput = createAction<{ typedValueLimit: string }>('limit/typeInput')
// export const typeOutputOrRate = createAction<{ field: FieldLimit; typedValueLimitOutputOrRate: string }>(
//   'limit/typeOutputOrRate'
// )
// export const typeLimitValues = createAction<{
//   typedLimitValueInput: string
//   typedLimitValueOutput: string
//   typedLimitValueRate: string
// }>('limit/typeValuesLimit')
// export const replaceLimitState = createAction<{
//   typedValueLimitInput: string
//   field: FieldLimit
//   typedValueLimitOutputOrRate: string // output or rate
//   // inputCurrencyId?: string
//   // outputCurrencyId?: string
//   // recipient: string | null
// }>('limit/replaceLimitState')
// export const setRecipient = createAction<{ recipient: string | null }>('swap/setRecipient')
export const updateInputs = createAction<UpdateInputsPayload>('limit/updateInputs')
export const switchCurrencies = createAction<void>('limit/switchCurrencies')
export const setInitialState = createAction<{ newState: boolean }>('limit/setInitialState')

// TODO: Back to start?
