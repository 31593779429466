/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrder2 = /* GraphQL */ `
  mutation CreateOrder2($input: CreateOrderInput!) {
    createOrder2(input: $input) {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
      createdAt
      updatedAt
    }
  }
`;
export const createTokenPairPool = /* GraphQL */ `
  mutation CreateTokenPairPool(
    $input: CreateTokenPairPoolInput!
    $condition: ModelTokenPairPoolConditionInput
  ) {
    createTokenPairPool(input: $input, condition: $condition) {
      chainId
      tokenPair
      chainIdAndTokenPair
      token1
      token2
      ordersSortedByExecutionPrice {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTokenPairPool = /* GraphQL */ `
  mutation UpdateTokenPairPool(
    $input: UpdateTokenPairPoolInput!
    $condition: ModelTokenPairPoolConditionInput
  ) {
    updateTokenPairPool(input: $input, condition: $condition) {
      chainId
      tokenPair
      chainIdAndTokenPair
      token1
      token2
      ordersSortedByExecutionPrice {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTokenPairPool = /* GraphQL */ `
  mutation DeleteTokenPairPool(
    $input: DeleteTokenPairPoolInput!
    $condition: ModelTokenPairPoolConditionInput
  ) {
    deleteTokenPairPool(input: $input, condition: $condition) {
      chainId
      tokenPair
      chainIdAndTokenPair
      token1
      token2
      ordersSortedByExecutionPrice {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet(
    $input: CreateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    createWallet(input: $input, condition: $condition) {
      chainId
      address
      chainIdAndAddress
      ordersSortedByExpiration {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWallet = /* GraphQL */ `
  mutation UpdateWallet(
    $input: UpdateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    updateWallet(input: $input, condition: $condition) {
      chainId
      address
      chainIdAndAddress
      ordersSortedByExpiration {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWallet = /* GraphQL */ `
  mutation DeleteWallet(
    $input: DeleteWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    deleteWallet(input: $input, condition: $condition) {
      chainId
      address
      chainIdAndAddress
      ordersSortedByExpiration {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
