import { Plural } from '@lingui/macro'
import { NavDropdown } from 'components/NavBar/NavDropdown'
// import * as styles from 'components/NavBar/NavDropdown.css'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import ms from 'ms.macro'
import { Column, Row } from 'nft/components/Flex'
import { NumericInput } from 'nft/components/layout/Input'
// import { Dropdown } from 'nft/components/profile/list/Dropdown'
import { bodySmall, caption } from 'nft/css/common.css'
// import { useSellAsset } from 'nft/hooks'
import { DropDownOption } from 'nft/types'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { AlertTriangle, ChevronDown } from 'react-feather'
import { Check } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { Z_INDEX } from 'theme/zIndex'

// import { Portal } from 'nft/components/common/Portal'
import { Portal } from './Portal'

const ModalWrapper = styled(Column)`
  gap: 4px;
  position: relative;
`

/* https://gist.github.com/meddokss/009cdb5192e487654c0d90cd8f692214 */
const InputWrapper = styled(Row)<{ $isInvalid: boolean }>`
  padding: 12px 8px 12px 12px;
  border: 1px solid;
  position: relative;
  height: 44px;
  border-radius: 8px;
  border-color: ${({ $isInvalid, theme }) => ($isInvalid ? theme.accentCritical : theme.backgroundOutline)};
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 48px;
  right: 0px;
  z-index: ${Z_INDEX.modal};
`

const DropdownPrompt = styled(Row)`
  gap: 4px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  padding: 6px 4px 6px 8px;
  width: min-content;
  white-space: nowrap;
  color: ${({ theme }) => theme.textPrimary};

  &:hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
`

/* https://gist.github.com/meddokss/009cdb5192e487654c0d90cd8f692214 */
const DropdownChevron = styled(ChevronDown)<{ $isOpen: boolean }>`
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.textSecondary};
  transform: ${({ $isOpen }) => $isOpen && 'rotate(180deg)'};
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `transform ${duration.fast} ${timing.ease}`};
`

const DropdownRow = styled(Row)`
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  border-radius: 12px;

  &:hover {
    background: ${({ theme }) => theme.backgroundInteractive};
  }
`

const ErrorMessage = styled(Row)`
  color: ${({ theme }) => theme.accentCritical};
  gap: 4px;
  position: absolute;
  top: 44px;
`

const WarningIcon = styled(AlertTriangle)`
  width: 16px;
  color: ${({ theme }) => theme.accentCritical};
`

enum Duration {
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
}

enum ErrorState {
  valid,
  empty,
  overMax,
}

interface LimitOrderDurationModalProps {
  onExpirationSelect: (value: number) => void
}

export const SetLimitOrderDurationModal = ({ onExpirationSelect }: LimitOrderDurationModalProps) => {
  const theme = useTheme()
  const [duration, setDuration] = useState(Duration.day)
  // const [displayDuration, setDisplayDuration] = useState(Duration.day)
  const [amount, setAmount] = useState('7')
  const [errorState, setErrorState] = useState(ErrorState.valid)
  // const setGlobalExpiration = useSellAsset((state) => state.setGlobalExpiration)
  const [showDropdown, toggleShowDropdown] = useReducer((s) => !s, false)
  const durationDropdownRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(durationDropdownRef, showDropdown ? toggleShowDropdown : undefined, [modalRef])
  const setCustomExpiration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value.length ? event.target.value : '')
    // setDuration(displayDuration)
  }
  // const selectDuration = (duration: Duration) => {
  //   setDuration(duration)
  //   setDisplayDuration(duration)
  // }
  // const durationOptions: DropDownOption[] = useMemo(
  //   () => [
  //     {
  //       displayText: 'Hours',
  //       onClick: () => selectDuration(Duration.hour),
  //     },
  //     {
  //       displayText: 'Days',
  //       onClick: () => selectDuration(Duration.day),
  //     },
  //     {
  //       displayText: 'Weeks',
  //       onClick: () => selectDuration(Duration.week),
  //     },
  //     {
  //       displayText: 'Months',
  //       onClick: () => selectDuration(Duration.month),
  //     },
  //   ],
  //   []
  // )

  const durationOptions: DropDownOption[] = useMemo(
    () => [
      {
        displayText: 'hours',
        isSelected: duration === Duration.hour,
        onClick: () => {
          setDuration(Duration.hour)
          toggleShowDropdown()
        },
      },
      {
        displayText: 'days',
        isSelected: duration === Duration.day,
        onClick: () => {
          setDuration(Duration.day)
          toggleShowDropdown()
        },
      },
      {
        displayText: 'weeks',
        isSelected: duration === Duration.week,
        onClick: () => {
          setDuration(Duration.week)
          toggleShowDropdown()
        },
      },
      {
        displayText: 'months',
        isSelected: duration === Duration.month,
        onClick: () => {
          // console.log('set month', duration)
          setDuration(Duration.month)
          toggleShowDropdown()
        },
      },
    ],
    [duration]
  )
  let prompt
  switch (duration) {
    case Duration.hour:
      prompt = <Plural value={amount} _1="hour" other="hours" />
      break
    case Duration.day:
      prompt = <Plural value={amount} _1="day" other="days" />
      break
    case Duration.week:
      prompt = <Plural value={amount} _1="week" other="weeks" />
      break
    case Duration.month:
      prompt = <Plural value={amount} _1="month" other="months" />
      break
    default:
      break
  }

  useEffect(() => {
    const expiration = convertDurationToExpiration(parseFloat(amount), duration)
    // console.log('duration', duration)
    if (expiration * 1000 - Date.now() < ms`60 seconds` || isNaN(expiration)) setErrorState(ErrorState.empty)
    else if (expiration * 1000 - Date.now() > ms`180 days`) setErrorState(ErrorState.overMax)
    else setErrorState(ErrorState.valid)
    // setGlobalExpiration(expiration)
    onExpirationSelect(expiration)
  }, [amount, duration, onExpirationSelect])

  const dropdown = (
    <NavDropdown top="372" left="auto" right="18" ref={modalRef}>
      <Column paddingX="8">
        {durationOptions.map((option) => (
          <DropdownRow key={option.displayText} onClick={option.onClick}>
            <ThemedText.BodyPrimary lineHeight="24px">{option.displayText}</ThemedText.BodyPrimary>
            {option.isSelected && <Check height={20} width={20} color={theme.accentAction} />}
          </DropdownRow>
        ))}
      </Column>
    </NavDropdown>
  )

  return (
    <ModalWrapper ref={durationDropdownRef}>
      <InputWrapper $isInvalid={errorState !== ErrorState.valid}>
        <NumericInput
          as="input"
          type="number"
          pattern="[0-9]"
          borderStyle="none"
          className={bodySmall}
          color={{ placeholder: 'textSecondary', default: 'textPrimary' }}
          value={amount}
          width="32"
          marginRight="4"
          backgroundColor="none"
          onChange={setCustomExpiration}
          flexShrink="0"
        />
        {/* <DropdownWrapper className={buttonTextMedium}>
          <SortDropdown
            dropDownOptions={durationOptions}
            mini
            miniPrompt={displayDuration + (displayDuration === duration ? pluralize(parseFloat(amount)) : 's')}
            left={38}
            top={38}
          />
        </DropdownWrapper> */}
        <DropdownPrompt onClick={toggleShowDropdown}>
          {prompt} <DropdownChevron $isOpen={showDropdown} />
        </DropdownPrompt>
        {showDropdown && (
          <DropdownContainer>
            <Portal>
              {/* <Dropdown dropDownOptions={durationOptions} width={125} /> */}
              {dropdown}
            </Portal>
          </DropdownContainer>
        )}
      </InputWrapper>
      {errorState !== ErrorState.valid && (
        <ErrorMessage className={caption}>
          {' '}
          <WarningIcon /> {errorState === ErrorState.overMax ? 'Maximum 6 months' : 'Set duration'}
        </ErrorMessage>
      )}
    </ModalWrapper>
  )
}

const convertDurationToExpiration = (amount: number, duration: Duration) => {
  const durationFactor = () => {
    switch (duration) {
      case Duration.hour:
        return 1
      case Duration.day:
        return 24
      case Duration.week:
        return 24 * 7
      default: // month
        return 24 * 30
    }
  }
  return Math.round((Date.now() + ms`1 hour` * durationFactor() * amount) / 1000)
}
