import { GraphQLQuery } from '@aws-amplify/api'
import { API, graphqlOperation } from 'aws-amplify'
import { onUpdateOrder } from 'graphql_aws/subscriptions'
import { useCallback, useEffect, useState } from 'react'

const getAccountAndOrders = /* GraphQL */ `
  query GetWallet($chainId: Int!, $address: String!) {
    getWallet(chainId: $chainId, address: $address) {
      chainId
      address
      chainIdAndAddress
      ordersSortedByExpiration {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          signature
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          txHash
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
        }
      }
    }
  }
`

// Define the GraphQL subscription to listen for new order creations
const onCreateOrder2 = graphqlOperation(`
  subscription OnCreateOrder2 {
    onCreateOrder2 {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
    }
  }
`)

export const useLimitOrders = (chainId: number, walletAddress: string) => {
  const [, setOrders] = useState<any[]>([])
  const [activeOrders, setActiveOrders] = useState<any[]>([])
  const [historicalOrders, setHistoricalOrders] = useState<any[]>([])

  // async function fetchLimitOrders() {
  const fetchLimitOrders = useCallback(async () => {
    try {
      // Call the getAccountAndOrders query to get the initial list of orders
      const data = await API.graphql<GraphQLQuery<any>>(
        graphqlOperation(getAccountAndOrders, { chainId, address: walletAddress })
      )
      // console.log('chainId', chainId)
      // console.log('walletAddress', walletAddress)
      // console.log('walletData', data)
      const walletData = data.data.getWallet
      if (!walletData) {
        setOrders([])
        setActiveOrders([])
        setHistoricalOrders([])
        return
      }
      const allOrders = walletData.ordersSortedByExpiration.items
      // console.log('allOrders', allOrders)
      if (!allOrders || allOrders.length === 0) {
        setOrders([])
        setActiveOrders([])
        setHistoricalOrders([])
        return
      }
      setOrders(allOrders)
      // Split orders into active and historical
      const ordersByStatus = allOrders.reduce((result: any, order: any) => {
        const currentTimestampInSeconds: number = Math.floor(Date.now() / 1000)
        const hasExpired = order.expiration < currentTimestampInSeconds
        const isCancelled = order.isCancelled
        const isNotFullyFilled =
          parseFloat(order.totalFilled) < parseFloat(order.totalSize) && parseFloat(order.totalSize) > 0
        const key = !hasExpired && !isCancelled && isNotFullyFilled ? 'Active' : 'Historical'
        if (!result[key]) {
          result[key] = []
        }
        // console.log('order', order)
        order && result[key].push(order)
        return result
      }, {} as { [key: string]: any[] })
      ordersByStatus['Active'] && setActiveOrders(ordersByStatus['Active'])
      // console.log('Active', ordersByStatus['Active'])
      ordersByStatus['Historical'] && setHistoricalOrders(ordersByStatus['Historical'])
      // console.log('Historical', ordersByStatus['Historical'])
    } catch (error) {
      console.error('Error fetching limit orders:', error)
    }
  }, [chainId, walletAddress])

  useEffect(() => {
    // Call the fetchOrders function when the component mounts
    fetchLimitOrders()

    // Subscribe to the onCreateOrder2 subscription to listen for new order creations
    const subscriptionCreate = (
      API.graphql(onCreateOrder2) as Exclude<ReturnType<typeof API.graphql>, Promise<any>>
    ).subscribe({
      next: (orderData: any) => {
        // When a new order is created, add it to the list of ACTIVE orders
        // console.log('activeOrders', activeOrders)
        // console.log('new orderData', orderData)
        if (
          orderData.value.data.onCreateOrder2.chainId === chainId &&
          orderData.value.data.onCreateOrder2.account === walletAddress
        ) {
          setActiveOrders((orders) => [...orders, orderData.value.data.onCreateOrder2])
        }
      },
      error: (error: any) => {
        console.error('Error subscribing to orderss:', error)
      },
    })

    const subscriptionCancel = (
      API.graphql(graphqlOperation(onUpdateOrder)) as Exclude<ReturnType<typeof API.graphql>, Promise<any>>
    ).subscribe({
      next: (cancelledOrder: any) => {
        // When a new order is created, add it to the list of ACTIVE orders
        // setActiveOrders((orders) => [...orders, orderData.value.data.onCreateOrder2])
        const cancelledOrderData = cancelledOrder.value.data.onUpdateOrder
        setActiveOrders((prevActiveOrders) => {
          const updatedActiveOrders = prevActiveOrders.filter(
            (obj) => obj.chainId !== cancelledOrderData.chainId || obj.orderId !== cancelledOrderData.orderId
          )
          return updatedActiveOrders
        })
        // setHistoricalOrders((prevHistoricalOrders) => {
        //   const updatedActiveOrders = prevActiveOrders.filter(
        //     (obj) => obj.chainId !== cancelledOrderData.chainId || obj.orderId !== cancelledOrderData.orderId
        //   )
        //   return updatedActiveOrders
        // })
        setHistoricalOrders((orders) => [...orders, cancelledOrderData])
      },
      error: (error: any) => {
        console.error('Error subscribing to orderss:', error)
      },
    })

    // Unsubscribe from the subscription when the component unmounts
    return () => {
      subscriptionCreate.unsubscribe()
      subscriptionCancel.unsubscribe()
    }
  }, [chainId, fetchLimitOrders, walletAddress])

  return { activeOrders, historicalOrders }
}
