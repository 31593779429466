import { Trans } from '@lingui/macro'
import { Currency, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import useAutoSlippageTolerance from 'hooks/useAutoSlippageTolerance'
import { useBestTrade } from 'hooks/useBestTrade'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ReactNode, useMemo } from 'react'
// import { ParsedQs } from 'qs'
import { useAppSelector } from 'state/hooks'
// import { InterfaceTrade, TradeState } from 'state/routing/types'
import { Field } from 'state/swap/actions'
import { useUserSlippageToleranceWithDefault } from 'state/user/hooks'
import { currencyAmountToPreciseFloat, formatTransactionAmountWeiX, priceToPreciseFloat } from 'utils/formatNumbers'

import { useCurrency } from '../../hooks/Tokens'
import useENS from '../../hooks/useENS'
// import { TOKEN_SHORTHANDS } from '../../constants/tokens'
// import useParsedQueryString from '../../hooks/useParsedQueryString'
import { isAddress } from '../../utils'
import { useCurrencyBalances } from '../connection/hooks'
import { AppState } from '../types'

function useSwapState(): AppState['swap'] {
  return useAppSelector((state) => state.swap)
}

export function useLimitState(): AppState['limit'] {
  return useAppSelector((state) => state.limit)
}

const BAD_RECIPIENT_ADDRESSES: { [address: string]: true } = {
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f': true, // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a': true, // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D': true, // v2 router 02
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedLimitInfo(): {
  inputError?: ReactNode
  formattedAmountInput: string
  formattedAmountOutput: string
  formattedAmountRate: string
} {
  const { account } = useWeb3React()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = useSwapState()

  const { limitInput, limitOutput, limitRate, isEmpty } = useLimitState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const recipientLookup = useENS(recipient ?? undefined)
  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [inputCurrency ?? undefined, outputCurrency ?? undefined], [inputCurrency, outputCurrency])
  )

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  )

  const trade = useBestTrade(
    isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    parsedAmount,
    (isExactIn ? outputCurrency : inputCurrency) ?? undefined
  )

  const formattedAmountInput = useMemo(
    () =>
      limitOutput && !limitRate
        ? formatTransactionAmountWeiX(currencyAmountToPreciseFloat(trade?.trade?.inputAmount))
        : limitInput,
    [limitInput, limitOutput, limitRate, trade?.trade?.inputAmount]
  )

  const formattedAmountOutput = useMemo(
    () =>
      limitInput && !limitRate && !isEmpty
        ? formatTransactionAmountWeiX(currencyAmountToPreciseFloat(trade?.trade?.outputAmount))
        : limitOutput,
    [isEmpty, limitInput, limitOutput, limitRate, trade?.trade?.outputAmount]
  )

  const formattedAmountRate = useMemo(
    () => limitRate,
    // (limitInput && limitOutput === '' && !isEmpty) || (limitOutput && limitInput === '')
    //   ? priceToPreciseFloat(trade?.trade?.executionPrice)?.toPrecision(6) ?? ''
    //   : limitRate,
    // ? formatTransactionAmount(priceToPreciseFloat(trade?.trade?.executionPrice))
    [limitRate]
  )

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  )

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  )

  // allowed slippage is either auto slippage, or custom user defined slippage if auto slippage disabled
  const autoSlippageTolerance = useAutoSlippageTolerance(trade.trade)
  const allowedSlippage = useUserSlippageToleranceWithDefault(autoSlippageTolerance)

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined

    if (!account) {
      inputError = <Trans>Connect Wallet</Trans>
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>
    }

    // if (!limitInput && !limitOutput) {
    if (
      !formattedAmountInput ||
      !formattedAmountOutput ||
      !parseFloat(formattedAmountInput) ||
      !parseFloat(formattedAmountOutput)
    ) {
      // if (!parsedAmount || !limitOutput || !limitInput) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>
    }

    const formattedTo = isAddress(to)
    if (!to || !formattedTo) {
      inputError = inputError ?? <Trans>Enter a recipient</Trans>
    } else {
      if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
        inputError = inputError ?? <Trans>Invalid recipient</Trans>
      }
    }

    // compare input balance to max input based on version
    const [balanceIn, amountIn] = [
      currencyBalances[Field.INPUT],
      tryParseCurrencyAmount(limitInput, inputCurrency ?? undefined) ?? trade.trade?.maximumAmountIn(allowedSlippage),
    ]

    if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
      inputError = <Trans>Insufficient {amountIn.currency.symbol} balance</Trans>
    }

    // Price too low error
    if (
      limitRate &&
      trade.trade?.executionPrice &&
      parseFloat(limitRate) < 0.99 * (priceToPreciseFloat(trade.trade?.executionPrice) ?? 0)
    ) {
      inputError = <Trans>Limit price lower than market</Trans>
    }

    return inputError
  }, [
    account,
    allowedSlippage,
    currencies,
    currencyBalances,
    formattedAmountInput,
    formattedAmountOutput,
    inputCurrency,
    limitInput,
    limitRate,
    to,
    trade.trade,
  ])

  return useMemo(
    () => ({
      inputError,
      formattedAmountInput,
      formattedAmountOutput,
      formattedAmountRate,
    }),
    [formattedAmountInput, formattedAmountOutput, formattedAmountRate, inputError]
  )
}
