/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOrder2 = /* GraphQL */ `
  subscription OnCreateOrder2 {
    onCreateOrder2 {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
    onCreateOrder(filter: $filter) {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
    onUpdateOrder(filter: $filter) {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
    onDeleteOrder(filter: $filter) {
      chainId
      orderId
      account
      chainIdAndAccount
      expiration
      inputToken
      outputToken
      inputAmount
      outputAmount
      chainIdAndTokenPairString
      executionPrice
      startTimestamp
      inSymbol
      inDecimals
      outSymbol
      outDecimals
      isCancelled
      isValidated
      totalFilled
      totalSize
      salt
      offerer
      zone
      zoneHash
      startTime
      endTime
      orderType
      offerItemType
      offerToken
      offerIdentifierOrCriteria
      offerStartAmount
      offerEndAmount
      considerationItemType
      considerationToken
      considerationIdentifierOrCriteria
      considerationStartAmount
      considerationEndAmount
      considerationRecipient
      totalOriginalConsiderationItems
      conduitKey
      counter
      signature
      txHash
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTokenPairPool = /* GraphQL */ `
  subscription OnCreateTokenPairPool(
    $filter: ModelSubscriptionTokenPairPoolFilterInput
  ) {
    onCreateTokenPairPool(filter: $filter) {
      chainId
      tokenPair
      chainIdAndTokenPair
      token1
      token2
      ordersSortedByExecutionPrice {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTokenPairPool = /* GraphQL */ `
  subscription OnUpdateTokenPairPool(
    $filter: ModelSubscriptionTokenPairPoolFilterInput
  ) {
    onUpdateTokenPairPool(filter: $filter) {
      chainId
      tokenPair
      chainIdAndTokenPair
      token1
      token2
      ordersSortedByExecutionPrice {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTokenPairPool = /* GraphQL */ `
  subscription OnDeleteTokenPairPool(
    $filter: ModelSubscriptionTokenPairPoolFilterInput
  ) {
    onDeleteTokenPairPool(filter: $filter) {
      chainId
      tokenPair
      chainIdAndTokenPair
      token1
      token2
      ordersSortedByExecutionPrice {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWallet = /* GraphQL */ `
  subscription OnCreateWallet($filter: ModelSubscriptionWalletFilterInput) {
    onCreateWallet(filter: $filter) {
      chainId
      address
      chainIdAndAddress
      ordersSortedByExpiration {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWallet = /* GraphQL */ `
  subscription OnUpdateWallet($filter: ModelSubscriptionWalletFilterInput) {
    onUpdateWallet(filter: $filter) {
      chainId
      address
      chainIdAndAddress
      ordersSortedByExpiration {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWallet = /* GraphQL */ `
  subscription OnDeleteWallet($filter: ModelSubscriptionWalletFilterInput) {
    onDeleteWallet(filter: $filter) {
      chainId
      address
      chainIdAndAddress
      ordersSortedByExpiration {
        items {
          chainId
          orderId
          account
          chainIdAndAccount
          expiration
          inputToken
          outputToken
          inputAmount
          outputAmount
          chainIdAndTokenPairString
          executionPrice
          startTimestamp
          inSymbol
          inDecimals
          outSymbol
          outDecimals
          isCancelled
          isValidated
          totalFilled
          totalSize
          salt
          offerer
          zone
          zoneHash
          startTime
          endTime
          orderType
          offerItemType
          offerToken
          offerIdentifierOrCriteria
          offerStartAmount
          offerEndAmount
          considerationItemType
          considerationToken
          considerationIdentifierOrCriteria
          considerationStartAmount
          considerationEndAmount
          considerationRecipient
          totalOriginalConsiderationItems
          conduitKey
          counter
          signature
          txHash
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
