/* eslint-disable import/no-unused-modules */
import { AddressZero } from '@ethersproject/constants'
import type { Web3Provider } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { API, graphqlOperation } from 'aws-amplify'
import AssetLogo from 'components/Logo/AssetLogo'
import { MouseoverTooltip } from 'components/Tooltip'
import { SeaportOrder } from 'graphql/data/Orders'
import { updateOrder } from 'graphql_aws/mutations'
import { useAtomValue } from 'jotai/utils'
import { Box } from 'nft/components/Box'
import { Seaport } from 'opensea/seaport-js'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useCallback } from 'react'
import { ArrowDown, ArrowUp, Info } from 'react-feather'
import Moment from 'react-moment'
// import { useParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components/macro'
import { ClickableStyle, ThemedText } from 'theme'
import { getEtherscanUrl } from 'utils/weixUtils'

import {
  LARGE_BREAKPOINT1,
  LARGE_BREAKPOINT2,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  SMALL_BREAKPOINT1,
  SMALL_BREAKPOINT2,
  SMALL_BREAKPOINT3,
  SMALL_BREAKPOINT4,
} from '../constants'
import { LoadingBubble } from '../loading'
import { OrderSortMethod, sortAscendingAtom, sortMethodAtom, useSetSortMethod } from '../state'

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledOrderRow = styled.div<{
  first?: boolean
  last?: boolean
  loading?: boolean
}>`
  background-color: transparent;
  display: grid;
  font-size: 16px;
  grid-template-columns: 18fr 18fr 18fr 18fr 18fr 10fr 12fr;
  line-height: 24px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  ${({ first, last }) => css`
    height: ${first || last ? '72px' : '64px'};
    padding-top: ${first ? '8px' : '0px'};
    padding-bottom: ${last ? '8px' : '0px'};
  `}
  padding-left: 12px;
  padding-right: 12px;
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  width: 100%;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ loading, theme }) =>
      !loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  @media only screen and (max-width: ${LARGE_BREAKPOINT2}) {
    grid-template-columns: 18fr 18fr 18fr 14fr 14fr 10fr 12fr;
  }

  @media only screen and (max-width: ${LARGE_BREAKPOINT1}) {
    grid-template-columns: 14fr 14fr 12fr 14fr 14fr 10fr 12fr;
  }

  @media only screen and (max-width: ${SMALL_BREAKPOINT4}) {
    grid-template-columns: 14fr 14fr 12fr 14fr 14fr 10fr;
  }

  @media only screen and (max-width: ${SMALL_BREAKPOINT3}) {
    grid-template-columns: 14fr 14fr 12fr 14fr 14fr;
  }

  @media only screen and (max-width: ${SMALL_BREAKPOINT2}) {
    grid-template-columns: 14fr 14fr 12fr 14fr;
  }

  @media only screen and (max-width: ${SMALL_BREAKPOINT1}) {
    grid-template-columns: 14fr 14fr 12fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.backgroundModule};

    :last-of-type {
      border-bottom: none;
    }
  }
`

const ClickableContent = styled.div`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.textPrimary};
  align-items: center;
`
const ClickableName = styled(ClickableContent)`
  gap: 8px;
  max-width: 100%;
`
const StyledHeaderRow = styled(StyledOrderRow)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.backgroundOutline};
  border-radius: 8px 8px 0px 0px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  height: 48px;
  line-height: 16px;
  padding: 0px 12px;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_BREAKPOINT1}) {
    justify-content: space-between;
  }
`
const HeaderCellWrapper = styled.span<{ onClick?: () => void }>`
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  width: 100%;

  &:hover {
    ${ClickableStyle}
  }
`
const DataCell = styled(Cell)<{ sortable: boolean }>`
  justify-content: flex-end;
  min-width: 80px;
  user-select: ${({ sortable }) => (sortable ? 'none' : 'unset')};
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
`
const NameCell = styled(Cell)`
  justify-content: flex-start;
  padding: 0px 8px;
  min-width: 160px;
  gap: 8px;
  @media only screen and (max-width: ${LARGE_BREAKPOINT1}) {
    min-width: 120px;
  }
`
const PriceCell = styled(DataCell)`
  justify-content: flex-start;
  padding: 0px 8px;
  min-width: 160px;
  gap: 8px;
  @media only screen and (max-width: ${LARGE_BREAKPOINT1}) {
    min-width: 100px;
  }
`
const CreateDateCell = styled(DataCell)`
  padding-right: 8px;
  min-width: 160px;
  @media only screen and (max-width: ${LARGE_BREAKPOINT2}) {
    min-width: 120px;
  }
  @media only screen and (max-width: ${SMALL_BREAKPOINT1}) {
    display: none;
  }
`
const ExpireDateCell = styled(DataCell)`
  padding-right: 8px;
  min-width: 160px;
  @media only screen and (max-width: ${LARGE_BREAKPOINT2}) {
    min-width: 120px;
  }
  @media only screen and (max-width: ${SMALL_BREAKPOINT2}) {
    display: none;
  }
`
const StatusCell = styled(DataCell)`
  padding-right: 8px;
  min-width: 80px;
  @media only screen and (max-width: ${SMALL_BREAKPOINT3}) {
    display: none;
  }
`
const ButtonCell = styled(Cell)`
  padding: 0px 24px;
  min-width: 100px;
  @media only screen and (max-width: ${SMALL_BREAKPOINT4}) {
    display: none;
  }
`
const TokenInfoCell = styled(Cell)`
  gap: 8px;
  line-height: 24px;
  font-size: 16px;
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: ${LARGE_BREAKPOINT1}) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0px;
    width: max-content;
    font-weight: 500;
  }
`
const DateInfoCell = styled(Cell)`
  gap: 8px;
  line-height: 24px;
  font-size: 16px;
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: ${LARGE_BREAKPOINT2}) {
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    gap: 0px;
    width: max-content;
    font-weight: 500;
  }
`
const TokenAmount = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`
const TokenSymbol = styled(Cell)`
  color: ${({ theme }) => theme.textTertiary};
  text-transform: uppercase;

  @media only screen and (max-width: ${LARGE_BREAKPOINT1}) {
    font-size: 12px;
    height: 16px;
    justify-content: flex-start;
    width: 100%;
  }
`
const CancelButton = styled.div<{ toggled: boolean; disabled?: boolean }>`
  display: flex;
  gap: 8px;
  border: none;
  border-radius: 12px;
  padding: 12px 18px 12px 12px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  color: ${({ toggled, disabled, theme }) => (toggled && !disabled ? theme.accentTextLightPrimary : theme.textPrimary)};
  background: ${({ theme, toggled, disabled }) =>
    !disabled && toggled
      ? 'radial-gradient(101.8% 4091.31% at 0% 0%, #4673FA 0%, #9646FA 100%)'
      : theme.backgroundInteractive};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  :hover {
    background-color: ${({ theme }) => theme.hoverState};
    transition: ${({
      theme: {
        transition: { duration, timing },
      },
    }) => `${duration.fast} background-color ${timing.in}`};
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 12px 12px 12px 12px;
  }
`
const CancelText = styled(ThemedText.BodyPrimary)`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    display: none;
  }
`

const MediumLoadingBubble = styled(LoadingBubble)`
  width: 65%;
`
const LongLoadingBubble = styled(LoadingBubble)`
  width: 90%;
`
const IconLoadingBubble = styled(LoadingBubble)`
  border-radius: 50%;
  width: 24px;
`
export const ButtonLoadingBubble = styled(LongLoadingBubble)`
  height: 4px;
`

const InfoIconContainer = styled.div`
  margin-left: 2px;
  display: flex;
  align-items: center;
  cursor: help;
`
const DateText = styled.span`
  // margin-right: 2px;
  color: ${({ theme }) => theme.textPrimary};
`
const TimeText = styled.span`
  // margin-left: 2px;
  color: ${({ theme }) => theme.textSecondary};
`

export const HEADER_DESCRIPTIONS: Record<OrderSortMethod, ReactNode | undefined> = {
  [OrderSortMethod.PRICE]: undefined,
  [OrderSortMethod.CREATED]: undefined,
  [OrderSortMethod.EXPIRATION]: undefined,
  [OrderSortMethod.FILLED]: undefined,
}

/* Get singular header cell for header row */
function HeaderCell({
  category,
}: {
  category: OrderSortMethod // TODO: change this to make it work for trans
}) {
  const theme = useTheme()
  const sortAscending = useAtomValue(sortAscendingAtom)
  const handleSortCategory = useSetSortMethod(category)
  const sortMethod = useAtomValue(sortMethodAtom)

  const description = HEADER_DESCRIPTIONS[category]

  return (
    <HeaderCellWrapper onClick={handleSortCategory}>
      {sortMethod === category && (
        <>
          {sortAscending ? (
            <ArrowUp size={20} strokeWidth={1.8} color={theme.accentActive} />
          ) : (
            <ArrowDown size={20} strokeWidth={1.8} color={theme.accentActive} />
          )}
        </>
      )}
      {category}
      {description && (
        <MouseoverTooltip text={description} placement="right">
          <InfoIconContainer>
            <Info size={14} />
          </InfoIconContainer>
        </MouseoverTooltip>
      )}
    </HeaderCellWrapper>
  )
}

/* Token Row: skeleton row component */
function OrderRow({
  header,
  sellToken,
  buyToken,
  price,
  created,
  expiration,
  fillStatus,
  buttonCol,
  ...rest
}: {
  first?: boolean
  header: boolean
  loading?: boolean
  sellToken: ReactNode
  buyToken: ReactNode
  price: ReactNode
  created: ReactNode
  expiration: ReactNode
  fillStatus: ReactNode
  buttonCol?: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <NameCell data-testid="name-cell">{sellToken}</NameCell>
      <NameCell data-testid="name-cell">{buyToken}</NameCell>
      <PriceCell data-testid="price-cell" sortable={header}>
        {price}
      </PriceCell>
      <CreateDateCell data-testid="create-date-cell" sortable={header}>
        {created}
      </CreateDateCell>
      <ExpireDateCell data-testid="expire-date-cell" sortable={header}>
        {expiration}
      </ExpireDateCell>
      <StatusCell data-testid="volume-cell" sortable={header}>
        {fillStatus}
      </StatusCell>
      <ButtonCell>{buttonCol}</ButtonCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="header-row">{rowCells}</StyledHeaderRow>
  return <StyledOrderRow {...rest}>{rowCells}</StyledOrderRow>
}

/* Header Row: top header row component for table */
export function HeaderRow({ isActive }: { isActive: boolean }) {
  return (
    <OrderRow
      header={true}
      sellToken={<Trans>You sell</Trans>}
      buyToken={<Trans>You buy</Trans>}
      price={<Trans>Price</Trans>}
      created={<HeaderCell category={OrderSortMethod.CREATED} />}
      expiration={<HeaderCell category={OrderSortMethod.EXPIRATION} />}
      fillStatus={<HeaderCell category={OrderSortMethod.FILLED} />}
      buttonCol={isActive ? <Trans>Action</Trans> : <Trans>Status</Trans>}
    />
  )
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <OrderRow
      header={false}
      loading
      sellToken={
        <>
          <IconLoadingBubble />
          <MediumLoadingBubble />
        </>
      }
      buyToken={
        <>
          <IconLoadingBubble />
          <MediumLoadingBubble />
        </>
      }
      price={<MediumLoadingBubble />}
      created={<LoadingBubble />}
      // tvl={<LoadingBubble />}
      expiration={<LoadingBubble />}
      fillStatus={<LoadingBubble />}
      buttonCol={<ButtonLoadingBubble />}
      {...props}
    />
  )
}

interface LoadedRowProps {
  tokenListIndex: number
  tokenListLength: number
  token: SeaportOrder //NonNullable<TopToken>
  sortRank: number
  account: string | undefined
  provider: Web3Provider | undefined
  isActive: boolean
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { tokenListIndex, tokenListLength, token: order, account, provider, isActive } = props

  const offer = order.parameters.offer[0]
  const consider = order.parameters.consideration[0]
  const startTime = parseInt(order.parameters.startTime) * 1000
  const endTime = parseInt(order.parameters.endTime) * 1000
  const totalFilled = parseFloat(order.parameters.totalFilled)
  const totalSize = parseFloat(order.parameters.totalSize)
  const assetUrlPrefix = 'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/'

  // const lowercaseChainName = useParams<{ chainName?: string }>().chainName?.toUpperCase() ?? 'ethereum'
  // const filterNetwork = lowercaseChainName.toUpperCase()
  // const chainId = CHAIN_NAME_TO_CHAIN_ID[filterNetwork]
  const chainId = order.chainId

  const handleCancelOrder = useCallback(async () => {
    const orderComponents = order.parameters
    const weixOrderId = order.weixOrderId
    if (account && provider) {
      try {
        // console.log('order to be canceled', orderComponents)
        const seaport = new Seaport(provider.getSigner(), { seaportVersion: '1.4' })
        const txHash = await seaport.cancelOrders([orderComponents], account).transact()
        if (txHash) {
          await API.graphql(
            graphqlOperation(updateOrder, {
              input: { chainId, orderId: weixOrderId, isCancelled: true, txHash: txHash.hash },
            })
          )
        }
      } catch (e) {
        // console.log('Cancel error: ', e)
      }
    }
  }, [account, chainId, order.parameters, order.weixOrderId, provider])

  // TODO: currency logo sizing mobile (32px) vs. desktop (24px)
  return (
    <div ref={ref}>
      <OrderRow
        header={false}
        sellToken={
          <ClickableName>
            <AssetLogo
              isNative={offer.token === AddressZero}
              chainId={chainId}
              address={offer.token}
              symbol={offer.symbol}
              backupImg={`${assetUrlPrefix}${offer.token}/logo.png`}
            />
            <TokenInfoCell>
              <TokenAmount data-cy="token-name">
                {Math.round(parseFloat(offer.startAmount) / 10 ** (offer.decimals - 5)) / 100000}
              </TokenAmount>
              <TokenSymbol>{offer.symbol}</TokenSymbol>
            </TokenInfoCell>
          </ClickableName>
        }
        buyToken={
          <ClickableName>
            <AssetLogo
              isNative={consider.token === AddressZero}
              chainId={chainId}
              address={consider.token}
              symbol={consider.symbol}
              backupImg={`${assetUrlPrefix}${consider.token}/logo.png`}
            />
            <TokenInfoCell>
              <TokenAmount data-cy="token-name">
                {Math.round(parseFloat(consider.startAmount) / 10 ** (consider.decimals - 5)) / 100000}
              </TokenAmount>
              <TokenSymbol>{consider.symbol}</TokenSymbol>
            </TokenInfoCell>
          </ClickableName>
        }
        price={
          <ClickableName>
            <TokenInfoCell>
              <TokenAmount data-cy="token-name">
                {`${
                  Math.round(
                    (parseFloat(consider.startAmount) / parseFloat(offer.startAmount)) *
                      10 ** (offer.decimals - consider.decimals + 5)
                  ) / 100000
                }`}
              </TokenAmount>
              <TokenSymbol>{`${consider.symbol} / ${offer.symbol}`}</TokenSymbol>
            </TokenInfoCell>
          </ClickableName>
        }
        created={
          <ClickableContent>
            <DateInfoCell>
              <DateText>
                <Moment format="YYYY-MM-DD">{startTime}</Moment>
              </DateText>
              <TimeText>
                <Moment format="HH:mm">{startTime}</Moment>
              </TimeText>
            </DateInfoCell>
          </ClickableContent>
        }
        expiration={
          <ClickableContent>
            <DateInfoCell>
              <DateText>
                <Moment format="YYYY-MM-DD">{endTime}</Moment>
              </DateText>
              <TimeText>
                <Moment format="HH:mm">{endTime}</Moment>
              </TimeText>
            </DateInfoCell>
          </ClickableContent>
        }
        fillStatus={
          <ClickableContent>
            {totalSize < 1e-6 ? '0%' : `${Math.round((totalFilled * 1000) / totalSize) / 10}%`}
          </ClickableContent>
        }
        buttonCol={
          isActive ? (
            <CancelButton
              // toggled={sweepIsOpen}
              // disabled={hasErc1155s}
              // className={buttonTextMedium}
              toggled={false}
              onClick={handleCancelOrder}
              // data-testid="nft-sweep-button"
            >
              <CancelText fontWeight={600} color="currentColor" lineHeight="20px">
                Cancel
              </CancelText>
            </CancelButton>
          ) : order.parameters.isCancelled ? (
            <Box as="a" href={getEtherscanUrl(chainId, 'tx', order.txHash)} target="_blank" rel="noopener noreferrer">
              <Trans>Cancelled</Trans>
            </Box>
          ) : totalFilled === totalSize && totalSize > 0 ? (
            <Box as="a" href={getEtherscanUrl(chainId, 'tx', order.txHash)} target="_blank" rel="noopener noreferrer">
              <Trans>Filled</Trans>
            </Box>
          ) : (
            endTime < Date.now() && <Trans>Expired</Trans>
          )
        }
        first={tokenListIndex === 0}
        last={tokenListIndex === tokenListLength - 1}
      />
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
