import { createReducer } from '@reduxjs/toolkit'
import { formatTransactionAmountWeiX } from 'utils/formatNumbers'

import { setInitialState, switchCurrencies, updateInputs } from './actions'

// eslint-disable-next-line import/no-unused-modules
export interface LimitState {
  // readonly independentFieldLimit: FieldLimit
  // readonly typedValueLimitInput: string
  // readonly typedValueLimitOutputOrRate: string
  readonly limitInput: string
  readonly limitOutput: string
  readonly limitRate: string
  readonly isEmpty: boolean
  readonly isInitialState: boolean
}

// const initialState: LimitState = queryParametersToSwapState(parsedQueryString())
const initialState: LimitState = {
  // independentFieldLimit: FieldLimit.RATE,
  // typedValueLimitInput: '',
  // typedValueLimitOutputOrRate: '',
  // typedLimitValueInput: '',
  // typedLimitValueOutput: '',
  // typedLimitValueRate: '',
  limitInput: '',
  limitOutput: '',
  limitRate: '',
  isEmpty: true,
  isInitialState: true,
}

export default createReducer<LimitState>(
  initialState,
  (builder) =>
    builder
      .addCase(updateInputs, (state, { payload: { updatedField, newValue } }) => {
        // return {
        //   typedLimitValueInput,
        //   typedLimitValueOutput,
        //   typedLimitValueRate,
        // }
        state[updatedField] = newValue
        state.isEmpty = newValue === ''
        // state.isInitialState = false

        if (updatedField === 'limitInput') {
          state.limitOutput = formatTransactionAmountWeiX(parseFloat(state.limitRate) * parseFloat(state.limitInput))
          // If inAmount new value is '', reset rate to 0
          if (!newValue) {
            state.limitRate = ''
            // state.isInitialState = true
          }
        } else if (updatedField === 'limitOutput') {
          // Update the dependent input based on input1 and input2
          state.limitRate = formatTransactionAmountWeiX(parseFloat(state.limitOutput) / parseFloat(state.limitInput))
        } else if (updatedField === 'limitRate') {
          // Update the dependent input based on input1 and input2
          // console.log('updating rate', newValue)
          // state.isInitialState = false
          state.limitOutput = formatTransactionAmountWeiX(parseFloat(state.limitRate) * parseFloat(state.limitInput))
          // if (!newValue) {
          //   state.limitRate = '0'
          // }
        }

        // if (!state.limitInput && !state.limitOutput) {
        //   state.isEmpty = true
        // }
      })
      .addCase(switchCurrencies, (state) => {
        ;[state.limitInput, state.limitOutput] = [state.limitOutput, state.limitInput]
        if (state.limitInput) {
          // state.limitRate = formatTransactionAmount(1 / parseFloat(state.limitRate))
          state.limitRate = formatTransactionAmountWeiX(parseFloat(state.limitOutput) / parseFloat(state.limitInput))
        }
      })
      .addCase(setInitialState, (state, { payload: { newState } }) => {
        state.isInitialState = newState
      })
  // .addCase(replaceLimitState, (state, { payload: { typedValueLimitInput, field, typedValueLimitOutputOrRate } }) => {
  //   return {
  //     independentFieldLimit: field,
  //     typedValueLimitInput,
  //     typedValueLimitOutputOrRate,
  //   }
  // })
  // .addCase(typeInput, (state, { payload: { typedValueLimit } }) => {
  //   return {
  //     ...state,
  //     typedValueLimit,
  //   }
  // })
  // .addCase(typeOutputOrRate, (state, { payload: { typedValueLimitOutputOrRate } }) => {
  //   return {
  //     ...state,
  //     typedValueLimitOutputOrRate,
  //   }
  // })
)
