// import { TraceEvent } from '@uniswap/analytics'
// import { BrowserEvent, InterfaceElementName, SwapEventName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { SetLimitOrderDurationModal } from 'components/limit/setLimitOrderDurationModal'
import { loadingOpacityMixin } from 'components/Loader/styled'
import { isSupportedChain } from 'constants/chains'
// import { Portal } from 'nft/components/common/Portal'
import { darken } from 'polished'
import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'

// import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
// import { useCurrencyBalance } from '../../state/connection/hooks'
// import { FiatValue } from '../CurrencyInputPanel/FiatValue'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween } from '../Row'

const PriceCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 24px;
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
  height: auto;
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  min-height: 44px;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
`

const InputRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: space-between;
`

const LabelRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 0.75rem;
  line-height: 1rem;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.textSecondary)};
  }
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
  min-height: 20px;
  padding: 8px 0px 0px 0px;
`

// const Aligner = styled.span`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
// `

// const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
//   margin: 0 0.25rem 0 0.35rem;
//   height: 35%;
//   margin-left: 8px;

//   path {
//     stroke: ${({ selected, theme }) => (selected ? theme.textPrimary : theme.white)};
//     stroke-width: 2px;
//   }
// `

// const StyledTokenName = styled.span<{ active?: boolean }>`
//   ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
//   font-size: 20px;
//   font-weight: 600;
// `

// const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
//   background-color: transparent;
//   border: none;
//   color: ${({ theme }) => theme.accentAction};
//   cursor: pointer;
//   font-size: 14px;
//   font-weight: 600;
//   opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
//   padding: 4px 6px;
//   pointer-events: ${({ disabled }) => (!disabled ? 'initial' : 'none')};

//   :hover {
//     opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
//   }

//   :focus {
//     outline: none;
//   }
// `

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
  font-size: 32px;
  line-height: 44px;
  font-variant: small-caps;
  color: ${({ theme }) => theme.accentAction};
`

interface LimitPriceInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: () => void
  onExpirationSelect: (expiration: number) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: CurrencyAmount<Token> | null
  priceImpact?: Percent
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
}

export default function LimitPriceAndExpirationInputPanel({
  value,
  onUserInput,
  // onMax,
  // showMaxButton,
  // onCurrencySelect,
  onExpirationSelect,
  currency,
  otherCurrency,
  id,
  // showCommonBases,
  // showCurrencyAmount,
  // disableNonToken,
  // renderBalance,
  fiatValue,
  // priceImpact,
  hideBalance = false,
  // pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  ...rest
}: LimitPriceInputPanelProps) {
  // const [modalOpen, setModalOpen] = useState(false)
  const [fiatValueIsLoading, setFiatValueIsLoading] = useState(false)
  const { chainId } = useWeb3React()
  // const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  // const theme = useTheme()

  // const handleDismissSearch = useCallback(() => {
  //   setModalOpen(false)
  // }, [setModalOpen])

  const chainAllowed = isSupportedChain(chainId)

  useEffect(() => {
    !!value && !fiatValue ? setFiatValueIsLoading(true) : setFiatValueIsLoading(false)
  }, [fiatValueIsLoading, value, fiatValue])

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {/* {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.DeprecatedLabel fontSize="12px" textAlign="center" padding="0 12px">
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </ThemedText.DeprecatedLabel>
          </AutoColumn>
        </FixedContainer>
      )} */}
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}>
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              disabled={!chainAllowed}
              $loading={loading}
            />
          )}
          {currency && otherCurrency && (
            <PriceCell>
              {currency.symbol} / {otherCurrency.symbol}
            </PriceCell>
          )}
          <SetLimitOrderDurationModal onExpirationSelect={onExpirationSelect} />
        </InputRow>
        {Boolean(!hideInput && !hideBalance) && (
          <FiatRow>
            <RowBetween>
              <span />
            </RowBetween>
          </FiatRow>
        )}
        {/* {Boolean(!hideInput && !hideBalance) && (
          <FiatRow>
            <RowBetween>
              <LoadingOpacityContainer $loading={loading}>
                <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} isLoading={fiatValueIsLoading} />
              </LoadingOpacityContainer>
              {account ? (
                <RowFixed style={{ height: '17px' }}>
                  <ThemedText.DeprecatedBody
                    color={theme.textSecondary}
                    fontWeight={400}
                    fontSize={14}
                    style={{ display: 'inline' }}
                  >
                    {!hideBalance && currency && selectedCurrencyBalance ? (
                      renderBalance ? (
                        renderBalance(selectedCurrencyBalance)
                      ) : (
                        <Trans>Balance: {formatCurrencyAmount(selectedCurrencyBalance, 4)}</Trans>
                      )
                    ) : null}
                  </ThemedText.DeprecatedBody>
                  {showMaxButton && selectedCurrencyBalance ? (
                    <TraceEvent
                      events={[BrowserEvent.onClick]}
                      name={SwapEventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                      element={InterfaceElementName.MAX_TOKEN_AMOUNT_BUTTON}
                    >
                      <StyledBalanceMax onClick={onMax}>
                        <Trans>Max</Trans>
                      </StyledBalanceMax>
                    </TraceEvent>
                  ) : null}
                </RowFixed>
              ) : (
                <span />
              )}
            </RowBetween>
          </FiatRow>
        )} */}
      </Container>
      {/* {onCurrencySelect && (
        // <CurrencySearchModal
        //   isOpen={modalOpen}
        //   onDismiss={handleDismissSearch}
        //   onCurrencySelect={onCurrencySelect}
        //   selectedCurrency={currency}
        //   otherSelectedCurrency={otherCurrency}
        //   showCommonBases={showCommonBases}
        //   showCurrencyAmount={showCurrencyAmount}
        //   disableNonToken={disableNonToken}
        // />
        <SetLimitOrderDurationModal onExpirationSelect={onExpirationSelect!} />
      )} */}
    </InputPanel>
  )
}
