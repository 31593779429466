/* eslint-disable import/no-unused-modules */
export const MAX_WIDTH_MEDIA_BREAKPOINT = '1200px'

export const SMALL_BREAKPOINT1 = '540px'
export const SMALL_BREAKPOINT2 = '680px'
export const SMALL_BREAKPOINT3 = '780px'
export const SMALL_BREAKPOINT4 = '900px'

export const LARGE_BREAKPOINT1 = '1040px'
export const LARGE_BREAKPOINT2 = '1120px'
