import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, InterfaceElementName, SwapEventName } from '@uniswap/analytics-events'
import { Currency, TradeType } from '@uniswap/sdk-core'
// import { useWeb3React } from '@web3-react/core'
import AnimatedDropdown from 'components/AnimatedDropdown'
import { AutoColumn } from 'components/Column'
// import LimitPriceInputPanel from 'components/PriceInputPanel/LimitPriceInputPanel'
import LimitPriceAndExpirationInputPanel from 'components/PriceInputPanel/LimitPriceAndExpirationInputPanel'
import Row, { RowBetween, RowFixed } from 'components/Row'
import { useState } from 'react'
import { InterfaceTrade } from 'state/routing/types'
import styled, { keyframes } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { priceToPreciseFloat } from 'utils/formatNumbers'

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: center;
  border-radius: inherit;
  padding: 8px 12px;
  margin-top: 0;
  min-height: 32px;
`

const PriceExpRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 6px;
  margin-bottom: 2px;
`
const PriceDiv = styled.div`
  display: flex;
  padding: 4px 6px;
`
const DeltaDiv = styled.div<{ positive?: boolean }>`
  margin-left: 2px;
  margin-right: 2px;
  color: ${({ theme, positive }) => (positive ? theme.accentSuccess : theme.accentFailure)};
`
const DummyDeltaDiv = styled.div`
  width: 40px;
  margin-left: 2px;
  margin-right: 2px;
`
const PadDiv = styled.div`
  padding: 4px 6px;
`

const StyledSetMarket = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.accentAction};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  padding: 4px 6px;
  pointer-events: ${({ disabled }) => (!disabled ? 'initial' : 'none')};

  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
  }

  :focus {
    outline: none;
  }
`

// const StyledInfoIcon = styled(Info)`
//   height: 16px;
//   width: 16px;
//   margin-right: 4px;
//   color: ${({ theme }) => theme.textTertiary};
// `

// const StyledCard = styled(OutlineCard)`
//   padding: 12px;
//   border: 1px solid ${({ theme }) => theme.backgroundOutline};
// `

const StyledHeaderRow = styled(RowBetween)<{ disabled: boolean; open: boolean }>`
  padding: 0;
  align-items: center;
  // cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

// const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
//   transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
//   transition: transform 0.1s linear;
// `

const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  margin-left: 10px;
  align-items: center;
  color: ${({ theme }) => theme.textPrimary};
  transition: 250ms ease color;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    display: none;
  `}
`

const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  transition: 250ms ease background-color;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.textPrimary};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 250ms ease border-color;
  left: -3px;
  top: -3px;
`

interface SwapDetailsInlineProps {
  value: string
  onUserInput: (value: string) => void
  onExpirationSelect: (value: number) => void
  onSetToMarket: () => void
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
  syncing: boolean
  loading: boolean
  isOpen: boolean
}

export default function LimitDetailsDropdown({
  value,
  onUserInput,
  onExpirationSelect,
  onSetToMarket,
  trade,
  syncing,
  loading,
  isOpen,
}: SwapDetailsInlineProps) {
  // const theme = useTheme()
  // const { chainId } = useWeb3React()
  const [showDetails, setShowDetails] = useState(false)

  // useEffect(() => {
  //   if (trade?.inputAmount) {
  //     setShowDetails(true)
  //   }
  // }, [trade?.inputAmount])

  // useEffect(() => {
  //   console.log('isOpen?', isOpen)
  // }, [isOpen])

  // function handleTypeInput(value: string): void {
  //   console.log('TODO: handleTypeInput of price')
  // }
  function computeDeltaPrice() {
    if (value && trade?.executionPrice) {
      const marketPrice = priceToPreciseFloat(trade.executionPrice)
      const limitPrice = parseFloat(value)
      if (marketPrice && marketPrice > 0 && limitPrice > 0) {
        return (limitPrice - marketPrice) / marketPrice
      }
    }
    return undefined
  }

  function handleSetCurrentPriceInput(): void {
    // console.log('TODO: handleMax use of set current price')
  }

  function handleExpirationSelect(): void {
    // console.log('TODO: handleExpirationSelect of expiration date')
  }

  const deltaPrice = computeDeltaPrice()

  return (
    <Wrapper style={{ marginTop: '0' }}>
      <AutoColumn gap="sm" style={{ width: '100%', marginBottom: '-8px' }}>
        <TraceEvent
          events={[BrowserEvent.onClick]}
          name={SwapEventName.SWAP_DETAILS_EXPANDED}
          element={InterfaceElementName.SWAP_DETAILS_DROPDOWN}
          shouldLogImpression={!showDetails}
        >
          <StyledHeaderRow disabled={!trade} open={isOpen}>
            <RowBetween style={{ position: 'relative' }}>
              {loading || syncing ? (
                <PriceExpRow>
                  <RowFixed>
                    <StyledPolling>
                      <StyledPollingDot>
                        <Spinner />
                      </StyledPollingDot>
                    </StyledPolling>
                    <ThemedText.DeprecatedMain fontSize={14}>
                      <Trans>Fetching current price...</Trans>
                    </ThemedText.DeprecatedMain>
                  </RowFixed>
                  <PadDiv>
                    <ThemedText.DeprecatedMain fontSize={14}>
                      <Trans>Expires in</Trans>
                    </ThemedText.DeprecatedMain>
                  </PadDiv>
                </PriceExpRow>
              ) : (
                trade && (
                  <PriceExpRow>
                    <PriceDiv>
                      <ThemedText.DeprecatedMain fontSize={14}>
                        <Trans>Limit price</Trans>
                      </ThemedText.DeprecatedMain>
                      {(deltaPrice == null || (deltaPrice > -0.01 && deltaPrice < 0.01)) && (
                        <DummyDeltaDiv></DummyDeltaDiv>
                      )}
                      {deltaPrice != null &&
                        (deltaPrice >= 0.01 ? (
                          <DeltaDiv positive={true}>{`(+${Math.round(deltaPrice * 100)}%)`}</DeltaDiv>
                        ) : (
                          deltaPrice <= -0.01 && (
                            <DeltaDiv positive={false}>{`(-${Math.round(-deltaPrice * 100)}%)`}</DeltaDiv>
                          )
                        ))}
                    </PriceDiv>
                    <StyledSetMarket
                      // onClick={() => {
                      //   // setToMarket(marketPrice)
                      //   console.log('Set to market')
                      // }}
                      onClick={onSetToMarket}
                    >
                      <Trans>Set to market</Trans>
                    </StyledSetMarket>
                    <PadDiv>
                      <ThemedText.DeprecatedMain fontSize={14}>
                        <Trans>Expires in</Trans>
                      </ThemedText.DeprecatedMain>
                    </PadDiv>
                  </PriceExpRow>
                )
              )}
            </RowBetween>
          </StyledHeaderRow>
        </TraceEvent>
        {/* <AnimatedDropdown open={showDetails}> */}
        <AnimatedDropdown open={isOpen}>
          <AutoColumn gap="sm" style={{ padding: '0', paddingBottom: '8px' }}>
            {
              /* // <StyledCard>
              //   <AdvancedSwapDetails trade={trade} allowedSlippage={allowedSlippage} syncing={syncing} />
              // </StyledCard> */

              <LimitPriceAndExpirationInputPanel
                label={<Trans>Price</Trans>}
                value={value}
                showMaxButton={false}
                currency={trade?.outputAmount.currency}
                onUserInput={onUserInput}
                onMax={handleSetCurrentPriceInput}
                fiatValue={undefined}
                onCurrencySelect={handleExpirationSelect}
                onExpirationSelect={onExpirationSelect}
                otherCurrency={trade?.inputAmount.currency}
                showCommonBases={true}
                id="limit-price-expiration-input"
                loading={false}
              />
            }
            {/* {trade ? <SwapRoute trade={trade} syncing={syncing} /> : null} */}
          </AutoColumn>
        </AnimatedDropdown>
      </AutoColumn>
    </Wrapper>
  )
}
