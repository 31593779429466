import { Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'

import { WRAPPED_NATIVE_CURRENCY } from '../constants/tokens'
import { useWETHContract } from './useContract'

/**
 * Given the selected input and output currency, return whether it is the ETH --> ERC20 patten
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 */
export default function useETH_ERC20Callback(
  inputCurrency: Currency | undefined | null,
  outputCurrency: Currency | undefined | null
): { isETH_ERC20: boolean; nativeSymbol?: string | undefined; wrapSymbol?: string | undefined } {
  const { chainId } = useWeb3React()
  const wethContract = useWETHContract()

  return useMemo(() => {
    const defaultOutput = { isETH_ERC20: false, nativeSymbol: undefined, wrapSymbol: undefined }
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return defaultOutput
    const weth = WRAPPED_NATIVE_CURRENCY[chainId]
    if (!weth) return defaultOutput
    if (inputCurrency.isNative && !(outputCurrency.isNative || weth.equals(outputCurrency))) {
      return { isETH_ERC20: true, nativeSymbol: inputCurrency.symbol, wrapSymbol: weth.symbol }
    }
    return defaultOutput
  }, [wethContract, chainId, inputCurrency, outputCurrency])
}
